<template>
    <component v-if="ready" :is="componentName" :key="improvement?.improvement_id">
        <!-- Will show when componentName does not exist -->
        <DefaultTemplate />
    </component>

    <div v-if="impNotFound">
        <ImprovementContainer @close-improvement="handleClose">
            <div class="improvement-empty-state-container">
                <img src="@/assets/img/improvement-error-state.png" style="height: 155px" />
                <Spacer height="2rem" />
                <div class="center" style="max-width: 362px">
                    <Text as="h5" size="f-5" weight="600" align="center">
                        Improvement could not be found
                    </Text>
                    <Spacer height="0.875rem" />
                    <Text as="p" size="f-8" align="center">
                        Your improvement could not be located in our database. Our product team has
                        been notified and will try to resolve the issue. Please close this page and
                        refresh to continue.
                    </Text>
                </div>
            </div>
        </ImprovementContainer>
    </div>
</template>
<script lang="ts">
/**
 * This improvement component loads in the correct template based on the
 * improvement rec action
 *
 * It's a component as active, completed and dismissed all share this logic. If you make changes
 * make sure to check all three improvement tabs.
 */

import { computed, defineComponent } from 'vue'
import camelCase from 'lodash-es/camelCase'
import upperFirst from 'lodash-es/upperFirst'
import { Text, Spacer } from '@opteo/components-next'
import ImprovementContainer from '@/components/improvement/ImprovementContainer.vue'

import { useImprovement } from '@/composition/improvement/useImprovement'
import { useLayouts } from '@/composition/layouts/useLayouts'
import { useAccount } from '@/composition/account/useAccount'
import { Platform } from '@opteo/types'
import DefaultTemplate from '@/components/improvement/DefaultTemplate.vue'

// Google Ads Improvements
import PauseAdGA from '@/components/improvement/types/ad-comparison/PauseAd.vue'
import PauseAdV2GA from '@/components/improvement/types/ad-comparison-v-2/PauseAdV2.vue'
import WriteAdGA from '@/components/improvement/types/ad-comparison/WriteAd.vue'
import WriteAdV2GA from '@/components/improvement/types/ad-comparison-v-2/WriteAdV2.vue'
import AdjustAdScheduleBidsGA from '@/components/improvement/types/ad-schedule/AdjustAdScheduleBids.vue'
import AdjustAttributionModelGA from '@/components/improvement/types/attribution-model/AdjustAttributionModel.vue'
import FixBelowFirstPageBidGA from '@/components/improvement/types/bids-below-first-page/FixBelowFirstPageBid.vue'
import CreateBrandCampaignGA from '@/components/improvement/types/branding/CreateBrandCampaign.vue'
import RemoveBroadKeywordsGA from '@/components/improvement/types/broad-keywords/RemoveBroadKeywords.vue'
import RemoveBroadKeywordsV2GA from '@/components/improvement/types/broad-keywords-v2/RemoveBroadKeywordsV2.vue'
import FixBrokenLinkGA from '@/components/improvement/types/broken-link/FixBrokenLink.vue'
import FixBrokenSitelinkGA from '@/components/improvement/types/broken-link/FixBrokenSitelink.vue'
import LiftCampaignBudgetCapGA from '@/components/improvement/types/budget-cap/LiftCampaignBudgetCap.vue'
import ReduceCampaignBidsGA from '@/components/improvement/types/budget-cap/ReduceCampaignBids.vue'
import AddCallExtensionGA from '@/components/improvement/types/account-level-extensions/AddCallExtension.vue'
import AddCalloutExtensionGA from '@/components/improvement/types/account-level-extensions/AddCalloutExtension.vue'
import AddSitelinkExtensionGA from '@/components/improvement/types/account-level-extensions/AddSitelinkExtension.vue'
import AddStructuredSnippetsExtensionGA from '@/components/improvement/types/account-level-extensions/AddStructuredSnippetsExtension.vue'
import AddCampaignLevelCalloutExtensionGA from '@/components/improvement/types/campaign-level-extensions/AddCampaignLevelCalloutExtension.vue'
import AddCampaignLevelSitelinkExtensionGA from '@/components/improvement/types/campaign-level-extensions/AddCampaignLevelSitelinkExtension.vue'
import AddCampaignLevelSnippetExtensionGA from '@/components/improvement/types/campaign-level-extensions/AddCampaignLevelSnippetExtension.vue'
import FixNegativesBlockingKeywordsGA from '@/components/improvement/types/check-negatives-blocking-keywords/FixNegativesBlockingKeywords.vue'
import AdjustProductGroupBidGA from '@/components/improvement/types/check-product-groups/AdjustProductGroupBid.vue'
import ResyncProductGroupsGA from '@/components/improvement/types/check-product-groups/ResyncProductGroups.vue'
import DemoImprovementGA from '@/components/improvement/types/demo/DemoImprovement.vue'
import AdjustAgeBidGA from '@/components/improvement/types/demographic-age-mod/AdjustAgeBid.vue'
import AdjustGenderBidGA from '@/components/improvement/types/demographic-gender-mod/AdjustGenderBid.vue'
import AdjustParentalBidGA from '@/components/improvement/types/demographic-parental-mod/AdjustParentalBid.vue'
import AdjustDeviceBidGA from '@/components/improvement/types/device-bids/AdjustDeviceBid.vue'
import AdjustSingleDeviceBidGA from '@/components/improvement/types/device-bids-v-2/AdjustSingleDeviceBid.vue'
import AdjustMultipleDeviceBidsGA from '@/components/improvement/types/device-bids-v-2/AdjustMultipleDeviceBids.vue'
import AddFrequencyCappingGA from '@/components/improvement/types/frequency-capping/AddFrequencyCapping.vue'
import AdjustGeoBidsGA from '@/components/improvement/types/geo-bids/AdjustGeoBids.vue'
import AdjustLocationBidsGA from '@/components/improvement/types/location-bids/AdjustLocationBids.vue'
import AdjustKeywordBidGA from '@/components/improvement/types/keyword-bids/AdjustKeywordBid.vue'
import PauseKeywordGA from '@/components/improvement/types/keyword-cutoff/PauseKeyword.vue'
import DisableInterestLocationsGA from '@/components/improvement/types/location-interests/DisableInterestLocations.vue'
import DisableInterestLocationsV2GA from '@/components/improvement/types/campaign-settings/DisableInterestLocationsV2.vue'
import ExcludeMobileAppsGA from '@/components/improvement/types/mobile-apps-exclusion/ExcludeMobileApps.vue'
import AddNegativeGeoKeywordGA from '@/components/improvement/types/negative-geo-keywords/AddNegativeGeoKeyword.vue'
import PauseDuplicateKeywordsGA from '@/components/improvement/types/pause-duplicate-keywords/PauseDuplicateKeywords.vue'
import PauseDuplicateKeywordsV2GA from '@/components/improvement/types/pause-duplicate-keywords-v2/PauseDuplicateKeywordsV2.vue'
import ExcludePlacementGA from '@/components/improvement/types/placement-exclusion/ExcludePlacement.vue'
import ExcludeContentGA from '@/components/improvement/types/content-exclusions/ExcludeContent.vue'
import CreateRemarketingCampaignGA from '@/components/improvement/types/remarketing/CreateRemarketingCampaign.vue'
import RemovePlussedNegativesGA from '@/components/improvement/types/remove-plussed-negatives/RemovePlussedNegatives.vue'
import ResyncExclusionListsGA from '@/components/improvement/types/resync-exclusion-lists/ResyncExclusionLists.vue'
import AddMissingCampaignsToSharedSetGA from '@/components/improvement/types/resync-shared-set-negative-lists/AddMissingCampaignsToSharedSet.vue'
import AddRobotNegativeGA from '@/components/improvement/types/robot-negatives/AddRobotNegative.vue'
import SearchPartnersSettingsGA from '@/components/improvement/types/search-partners-settings/SearchPartnersSettings.vue'
import DisableSearchPartnersGA from '@/components/improvement/types/search-partners/DisableSearchPartners.vue'
import AddSiteExclusionsGA from '@/components/improvement/types/site-category-exclusions/AddSiteExclusions.vue'
import AddSkagV2GA from '@/components/improvement/types/skag/AddSkagV2.vue'
import CheckAdSpellingGA from '@/components/improvement/types/spell-checker/CheckAdSpelling.vue'
import CheckQueryRelevanceGA from '@/components/improvement/types/sqr/CheckQueryRelevance.vue'
import AddNegativeListShoppingGA from '@/components/improvement/types/sqr-shopping/AddNegativeListShopping.vue'
import AdjustSinglePlacementDemoBidGA from '@/components/improvement/types/demo-bids/AdjustSinglePlacementDemoBid.vue'
import ExcludeSingleDeviceGA from '@/components/improvement/types/device-bids-v-2/ExcludeSingleDevice.vue'
import ExcludeSingleDemographicGA from '@/components/improvement/types/demo-bids/ExcludeSingleDemographic.vue'
import AdjustMultiplePlacementDemoBidsGA from '@/components/improvement/types/demo-bids/AdjustMultiplePlacementDemoBids.vue'
import AdjustKeywordBidV2GA from '@/components/improvement/types/keyword-bids-v2/AdjustKeywordBidV2.vue'
import IncreaseKeywordBidV2GA from '@/components/improvement/types/keyword-bids-v2/IncreaseKeywordBidV2.vue'
import PauseKeywordV2GA from '@/components/improvement/types/keyword-bids-v2/PauseKeywordV2.vue'
import AddNegativeNgramGA from '@/components/improvement/types/ngram/AddNegativeNgram.vue'
import ReduceNgramBidsGA from '@/components/improvement/types/ngram/ReduceNgramBids.vue'
import AdjustAdGroupMultiTargetsGA from '@/components/improvement/types/target-adjustments/AdjustAdGroupMultiTargets.vue'
import ExcludeLocationGA from '@/components/improvement/types/exclude-location/ExcludeLocation.vue'

// Microsoft Ads Improvements
import CheckQueryRelevanceMS from '@/components/improvement/types/sqr/CheckQueryRelevance.ms.vue'
import PauseKeywordV2MS from '@/components/improvement/types/keyword-bids-v2/PauseKeywordV2.ms.vue'
import AdjustKeywordBidV2MS from '@/components/improvement/types/keyword-bids-v2/AdjustKeywordBidV2.ms.vue'
import IncreaseKeywordBidV2MS from '@/components/improvement/types/keyword-bids-v2/IncreaseKeywordBidV2.ms.vue'

export default defineComponent({
    name: 'Improvement',
    components: {
        ImprovementContainer,
        DefaultTemplate,
        Text,
        Spacer,
        // ===
        // --- Google Ads Improvements ---
        // ===
        PauseAdGA,
        PauseAdV2GA,
        WriteAdGA,
        WriteAdV2GA,
        AdjustAdScheduleBidsGA,
        AdjustAttributionModelGA,
        FixBelowFirstPageBidGA,
        CreateBrandCampaignGA,
        RemoveBroadKeywordsGA,
        RemoveBroadKeywordsV2GA,
        FixBrokenLinkGA,
        FixBrokenSitelinkGA,
        LiftCampaignBudgetCapGA,
        ReduceCampaignBidsGA,
        AddCallExtensionGA,
        AddCalloutExtensionGA,
        AddCampaignLevelCalloutExtensionGA,
        AddCampaignLevelSitelinkExtensionGA,
        AddCampaignLevelSnippetExtensionGA,
        FixNegativesBlockingKeywordsGA,
        AdjustProductGroupBidGA,
        ResyncProductGroupsGA,
        DemoImprovementGA,
        AdjustAgeBidGA,
        AdjustGenderBidGA,
        AdjustParentalBidGA,
        AdjustDeviceBidGA,
        AdjustSingleDeviceBidGA,
        AdjustMultipleDeviceBidsGA,
        AddFrequencyCappingGA,
        AdjustGeoBidsGA,
        AdjustLocationBidsGA,
        AdjustKeywordBidGA,
        PauseKeywordGA,
        DisableInterestLocationsGA,
        DisableInterestLocationsV2GA,
        ExcludeMobileAppsGA,
        AddNegativeGeoKeywordGA,
        PauseDuplicateKeywordsGA,
        PauseDuplicateKeywordsV2GA,
        ExcludePlacementGA,
        ExcludeContentGA,
        CreateRemarketingCampaignGA,
        RemovePlussedNegativesGA,
        ResyncExclusionListsGA,
        AddMissingCampaignsToSharedSetGA,
        AddRobotNegativeGA,
        SearchPartnersSettingsGA,
        DisableSearchPartnersGA,
        AddSiteExclusionsGA,
        AddSitelinkExtensionGA,
        AddSkagV2GA,
        CheckAdSpellingGA,
        CheckQueryRelevanceGA,
        AddNegativeListShoppingGA,
        AddStructuredSnippetsExtensionGA,
        AdjustSinglePlacementDemoBidGA,
        ExcludeSingleDemographicGA,
        ExcludeSingleDeviceGA,
        AdjustMultiplePlacementDemoBidsGA,
        AdjustKeywordBidV2GA,
        PauseKeywordV2GA,
        IncreaseKeywordBidV2GA,
        ReduceNgramBidsGA,
        AddNegativeNgramGA,
        AdjustAdGroupMultiTargetsGA,
        ExcludeLocationGA,

        // ===
        // --- Microsoft Ads Improvements ---
        // ===
        CheckQueryRelevanceMS,
        PauseKeywordV2MS,
        AdjustKeywordBidV2MS,
        IncreaseKeywordBidV2MS,
    },
    setup() {
        useLayouts('full-screen')
        const { improvement, impNotFound, handleClose } = useImprovement()
        const { currencyCode, accountPlatform } = useAccount()

        const componentName = computed(() => {
            const platformId = Platform.PlatformToPrefix[accountPlatform.value ?? 0]

            const componentName = upperFirst(camelCase(improvement.value?.rec_action))
            const componentWithPlatform = `${componentName}${platformId?.toUpperCase()}`

            return componentWithPlatform
        })

        const ready = computed(() => improvement.value?.rec_action && currencyCode.value)

        return { componentName, improvement, ready, impNotFound, handleClose }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.improvement-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 30rem;
    @include ph-16;
}

@media (min-width: $mq-768-min) {
    .improvement-empty-state-container {
        padding-left: unset;
        padding-right: unset;
    }
}
</style>
