<template>
    <div class="google-serp-outer-container">
        <div class="google-serp-container">
            <!-- Mobile -->
            <div v-if="isMobileMode" id="mobile-preview" class="google-serp-container-mobile">
                <div class="mobile-wrapper">
                    <div class="mobile-top">
                        <div class="mobile-header">
                            <GoogleMenuIcon />
                            <GoogleLogo />
                            <GoogleAppsIcon />
                        </div>
                        <div class="search-bar">
                            <div class="search-query">
                                <GoogleSearchIcon hex="#9aa0a6" style="flex-shrink: 0" />
                                <Spacer width="0.5rem" />
                                <span>{{ searchQuery }}</span>
                            </div>
                            <GoogleCloseIcon />
                        </div>
                        <!-- Tabs -->
                        <div class="tabs">
                            <div class="tab selected">All</div>
                            <div class="tab">Shopping</div>
                            <div class="tab">Images</div>
                            <div class="tab">Maps</div>
                            <div class="tab">News</div>
                            <div class="tab">Videos</div>
                            <div class="tab">Books</div>
                            <div class="tab">Flights</div>
                        </div>
                    </div>
                    <!-- Card -->
                    <div class="content-container">
                        <div class="ad-container">
                            <!-- Ad URL -->
                            <div v-if="displayUrl" class="ad-url">
                                <b>Ad</b>
                                <svg
                                    style="width: 0.125rem; height: 0.125rem; flex-shrink: 0; outline: none"
                                    viewBox="0 0 2 2"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="1" cy="1" r="1" fill="#202124" />
                                </svg>
                                {{ displayUrl }}
                            </div>
                            <!-- Headlines -->
                            <p class="headlines clamp" :style="{ '-webkit-line-clamp': 2 }">
                                <span v-for="(headline, index) in headlines">
                                    <span v-if="headline.text != ''">
                                        {{ headline.text }}<span v-if="index + 1 != headlines.length"> | </span>
                                    </span>
                                </span>
                            </p>
                            <!-- Descriptions -->
                            <p class="descriptions clamp" :style="{ '-webkit-line-clamp': 3 }">
                                <span v-for="description in descriptions">
                                    <span v-if="description.text != ''"> {{ description.text }}&nbsp;</span>
                                </span>
                            </p>
                            <!-- <Spacer v-if="statistics" height="1.25rem" /> -->
                            <!-- Statistics -->
                            <!-- <perfect-scrollbar v-if="statistics" class="statistics">
                        <div v-for="statistic in statistics" :key="statistic.title" class="statistic">
                            <ColorTag :title="statistic.title" :content="statistic.value" :color="'blue'" />
                        </div>
                    </perfect-scrollbar> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Desktop -->
            <div v-else id="desktop-preview" class="google-serp-container-desktop">
                <!-- Search Bar -->
                <div class="search-bar-container">
                    <GoogleGIcon class="mobile-show" />
                    <GoogleLogo class="mobile-hide" style="width: auto; height: 2rem;" />
                    <div class="search-bar">
                        <span>{{ searchQuery }}</span>
                        <GoogleSearchIcon hex="#4285f4" />
                    </div>
                </div>
                <!-- Tabs -->
                <div class="tabs">
                    <div class="tab all selected">
                        <GoogleAllResultsIcon style="margin-right: 0.375rem" />
                        <span>All</span>
                    </div>
                    <div class="tab images">
                        <GoogleImagesIcon style="margin-right: 0.375rem" />
                        <span>Images</span>
                    </div>
                    <div class="tab maps">
                        <GoogleMapsIcon style="margin-right: 0.25rem" />
                        <span>Maps</span>
                    </div>
                    <div class="tab news">
                        <GoogleNewsIcon style="margin-right: 0.375rem" />
                        <span>News</span>
                    </div>
                    <div class="tab video">
                        <GoogleVideoIcon style="margin-right: 0.375rem" />
                        <span>Video</span>
                    </div>
                    <div class="tab more">
                        <GoogleMoreIcon style="margin-right: 0.125rem" />
                        <span>More</span>
                    </div>
                </div>
                <!-- Ad -->
                <div class="content-container">
                    <div class="ad-container">
                        <!-- Ad URL -->
                        <div v-if="displayUrl" class="ad-url">
                            <b>Ad</b>
                            <svg
                                style="width: 0.125rem; height: 0.125rem; flex-shrink: 0; outline: none"
                                viewBox="0 0 2 2"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="1" cy="1" r="1" fill="#202124" />
                            </svg>
                            {{ displayUrl }}
                        </div>
                        <!-- Headlines -->
                        <p class="headlines clamp" :style="{ '-webkit-line-clamp': 1 }">
                            <span v-for="(headline, index) in headlines">
                                <span v-if="headline.text != ''">
                                    {{ headline.text }}<span v-if="index + 1 != headlines.length"> | </span>
                                </span>
                            </span>
                        </p>
                        <!-- Descriptions -->
                        <p class="descriptions clamp" :style="{ '-webkit-line-clamp': 2 }">
                            <span v-for="description in descriptions">
                                <span v-if="description.text != ''"> {{ description.text }}&nbsp;</span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="rsa-preview-controls">
                <div class="left">
                    <RsaButton :double="true" @left-clicked="isMobileMode = false" @right-clicked="isMobileMode = true">
                        <template v-slot:left>
                            <rsaDesktopIcon :hex="isMobileMode ? '#000' : '#006dff'" />
                        </template>
                        <template v-slot:right>
                            <rsaMobileIcon :hex="isMobileMode ? '#006dff' : '#000'" />
                        </template>
                    </RsaButton>
                    <Spacer width="0.75rem" />
                    <RsaButton :double="true" @left-clicked="prevPermutation()" @right-clicked="nextPermutation()">
                        <template v-slot:left>
                            <rsaLeftArrowIcon />
                        </template>
                        <template v-slot:right>
                            <rsaRightArrowIcon />
                        </template>
                    </RsaButton>
                </div>
                <div class="right"><RsaSavedStatus :saving="saving" /></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed, toRef } from 'vue'
import { RsaWriter } from '@opteo/types'

import {
    Spacer,
    oTable,
    ColorTag,
    GoogleLogo,
    GoogleAllResultsIcon,
    GoogleImagesIcon,
    GoogleMapsIcon,
    GoogleMoreIcon,
    GoogleNewsIcon,
    GoogleVideoIcon,
    GoogleSearchIcon,
    GoogleAppsIcon,
    GoogleCloseIcon,
    GoogleMenuIcon,
    GoogleGIcon,
    rsaDesktopIcon,
    rsaMobileIcon,
    rsaLeftArrowIcon,
    rsaRightArrowIcon,
} from '@opteo/components-next'
import RsaButton from '@/components/rsaWriter/RsaButton.vue'
import RsaSavedStatus from '@/components/rsaWriter/RsaSavedStatus.vue'


import { useRsaPermutations, useRsaStatistics, stripAdCustomisers, useRsaDisplayUrl } from './useRsa'

export default defineComponent({
    name: 'GoogleSerpContainer',
    components: {
        Spacer,
        ColorTag,
        GoogleLogo,
        GoogleAllResultsIcon,
        GoogleImagesIcon,
        GoogleMapsIcon,
        GoogleMoreIcon,
        GoogleNewsIcon,
        GoogleVideoIcon,
        GoogleSearchIcon,
        GoogleAppsIcon,
        GoogleCloseIcon,
        GoogleMenuIcon,
        GoogleGIcon,
        RsaButton,
        rsaDesktopIcon,
        rsaMobileIcon,
        rsaLeftArrowIcon,
        rsaRightArrowIcon,
        RsaSavedStatus,
        oTable,
    },
    props: {
        rsa: {
            type: Object as PropType<RsaWriter.RsaAd | RsaWriter.NewRsaAd>,
            required: true,
        },
        originalRsa: {
            type: Object as PropType<RsaWriter.RsaAd | RsaWriter.NewRsaAd>,
            required: false,
        },
        searchQuery: {
            type: String,
            required: true,
        },
        saving: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    setup(props) {
        const isMobileMode = ref(false)

        const displayUrl = useRsaDisplayUrl(toRef(props, 'rsa'))

        const { currentPermutation, nextPermutation, prevPermutation } = useRsaPermutations(toRef(props, 'rsa'))
        const { statistics } = props.rsa.metrics ? useRsaStatistics(toRef(props, 'rsa')) : { statistics: null }

        const headlines = computed(() =>
            currentPermutation.value.headlines.map((headline) => {
                return {
                    text: stripAdCustomisers(headline.text),
                    pinnedTo: headline.pinnedTo,
                }
            }),
        )

        const descriptions = computed(() =>
            currentPermutation.value.descriptions.map((description) => {
                return {
                    text: stripAdCustomisers(description.text),
                    pinnedTo: description.pinnedTo,
                }
            }),
        )

        return {
            isMobileMode,
            displayUrl,
            headlines,
            descriptions,
            prevPermutation,
            nextPermutation,
            statistics,
            RsaStatus: RsaWriter.RsaStatus,
            stripAdCustomisers,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

#desktop-preview {
    font-family: 'Google Sans Text', 'Arial', sans-serif;
    font-feature-settings: 'ss01' off, 'ss02' off, 'ss04' off, 'ss05' off, 'ss08' off;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
    font-style: normal;
    font-weight: 400;
}

.google-logo {
    min-width: 92px;
    width: 92px;
    flex-shrink: 0;
    height: 30px;
}

.google-serp-container {
    @include container;
    @include br-24;
}

.google-serp-container-desktop {
    // @include container;
    @include pt-32;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-height: 19.6875rem;
}

.google-serp-container-desktop .search-bar-container {
    padding-left: 40px;
    padding-right: 40px;
    gap: 28px;
    @include flex;
    @include items-center;
}

.google-serp-container-desktop .search-bar {
    background: #fff;
    border: 1px solid rgba(2, 2, 12, 0.07);
    box-shadow: none;
    width: 700px;
    border-radius: 24px;
    height: 44px;
    padding: 0 18px 0 20px;
    font-family: 'Arial', sans-serif;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    @include flex;
    @include items-center;
}

.google-serp-container-desktop .search-bar span {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.87);
    word-wrap: break-word;
    outline: none;
    display: flex;
    flex: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Google Sans Text', sans-serif;
    font-size: 16px;
    line-height: 42px;
    height: 42px !important;
    text-transform: lowercase;
}

.google-serp-container-desktop .tabs {
    padding: 10px 0 0 40px;
    border-bottom: 1px solid rgba(2, 2, 12, 0.05);
    @include flex;
    @include items-center;
    @include no-select;
}
.google-serp-container-desktop .tabs .tab {
    height: 50px;
    font-family: 'Google';
    font-weight: 400;
    -webkit-font-smoothing: subpixel-antialiased;
    line-height: 22px;
    padding: 12px 4px 10px 0px;
    font-size: 14px;
    color: #5f6368;
    display: flex;
    align-items: center;
    @include mr-24;
}
.google-serp-container-desktop .tabs .tab.video {
    @include mr-20;
}

.google-serp-container-desktop .tabs .tab.selected {
    color: #1a73e8;
    padding-bottom: 7px;
    border-bottom: 3px solid #1a73e8;
    font-weight: 400;
    margin-left: 140px;
    align-items: center;
}

.google-serp-container-desktop .content-container {
    margin-left: 11.25rem;
    margin-right: 2rem;
    max-width: 37.5rem;
    min-height: 4.5rem;
    @include pb-40;
}

// Mobile

.google-serp-container-mobile {
    // @include container;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    font-feature-settings: 'ss01' off, 'ss02' off, 'ss04' off, 'ss05' off, 'ss08' off;
    // Version A
    padding: 2.0625rem 0;

    // Version B
    // padding: 0;

    width: 100%;
}

.google-serp-container-mobile .mobile-wrapper {
    // Version A
    @include container;
    @include br-16;

    // Version B
    // border-left: 1px solid #eeeeef;
    // border-right: 1px solid #eeeeef;

    max-width: 25.125rem;
    min-height: 22rem;
    margin: 0 auto;
    overflow: hidden;
}
.google-serp-container-mobile .mobile-wrapper .mobile-top {
    box-shadow: rgb(0 0 0 / 6%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 1px 3px;
}
.google-serp-container-mobile .mobile-wrapper .mobile-header {
    @include flex;
    @include items-center;
    @include justify-between;
    @include pa-20;
}
.google-serp-container-mobile .mobile-wrapper .search-bar {
    @include flex;
    @include items-center;
    @include justify-between;
    @include mh-16;
    border: 1px solid rgba(2, 2, 12, 0.07);
    box-shadow: none;
    width: calc(100% - 2rem);
    border-radius: 22px;
    height: 44px;
    padding: 0 1rem;
    font-family: 'Arial', sans-serif;
}
.google-serp-container-mobile .mobile-wrapper .search-bar .search-query {
    @include flex;
    @include items-center;
}
.google-serp-container-mobile .mobile-wrapper .search-bar .search-query span {
    -webkit-tap-highlight-color: transparent;
    font-family: 'Google Sans Text', sans-serif;
    font-size: 15px;
    line-height: 42px;
    height: 42px !important;
    text-transform: lowercase;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
}

.google-serp-container-mobile .mobile-wrapper .tabs {
    padding: 0 0 8px 20px;
    @include flex;
    @include items-center;
    @include relative;
}
.google-serp-container-mobile .mobile-wrapper .tabs::after {
    content: '';
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 100%;
}
.google-serp-container-mobile .mobile-wrapper .tabs .tab {
    font-family: 'Google Sans Text', sans-serif;
    line-height: 16px;
    -webkit-font-smoothing: antialiased;
    padding: 16px 0 4px 0;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    border-bottom: 2px solid #fff;
    margin-right: 1.25rem;
}
.google-serp-container-mobile .mobile-wrapper .tabs .tab.selected {
    border-bottom: 2px solid #000;
    color: #000;
}
.google-serp-container-mobile .mobile-wrapper .content-container .ad-container {
    @include mt-0;
    @include pa-20;
}

// RSA
.google-serp-container .ad-container {
    @include block;
    max-width: 37.5rem;
    width: 100%;
    margin: 2.5rem auto 0 auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
.google-serp-container-mobile .ad-container {
    max-width: 25.875rem;
}
.google-serp-container .ad-container .ad-url,
.google-serp-container-mobile .ad-container .ad-url {
    @include flex;
    @include items-center;
    font-family: Arial, sans-serif;
    color: #202124;
    font-size: 0.875rem;
    letter-spacing: 0;
    white-space: nowrap;
}
.google-serp-container-mobile .ad-container .ad-url {
    font-size: 0.75rem;
}
.google-serp-container .ad-container .ad-url b,
.google-serp-container-mobile .ad-container .ad-url b {
    letter-spacing: -0.4px;
}
.google-serp-container .ad-container .ad-url svg {
    margin: 0 0.3125rem;
}
.google-serp-container-mobile .ad-container .ad-url svg {
    margin: 0 0.25rem 0 0.3125rem;
}
.google-serp-container .ad-container .headlines,
.google-serp-container-mobile .ad-container .headlines {
    @include block;
    font-family: 'Google Sans Text', sans-serif;
    font-size: 1.25rem;
    line-height: 1.625rem;
    letter-spacing: -0.005rem;
    color: #1a0dab;
    margin-top: 0.5rem;
    margin-bottom: 0.375rem;
    -webkit-font-smoothing: subpixel-antialiased;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include no-select;
}
.google-serp-container-mobile .ad-container .headlines {
    margin-top: 0.625rem;
    margin-bottom: 0.5rem;
    line-height: 1.75rem;
}
.google-serp-container .ad-container .descriptions,
.google-serp-container-mobile .ad-container .descriptions {
    @include block;
    font-family: Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    color: #4d5156;
    max-width: 600px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include no-select;
}
.google-serp-container-mobile .ad-container .statistics,
.google-serp-container .ad-container .statistics {
    @include inline-flex;
    @include items-center;
}

.google-serp-container-mobile .ad-container .statistics {
    @include container;
    max-width: 100%;
    padding: 1rem;
}

.ad-container .statistic {
    margin-right: 0.375rem;
}

// RSA Preview Controls

.rsa-preview-controls {
    @include flex;
    @include items-center;
    @include justify-between;
    @include ph-32;
    @include pb-32;
}
.rsa-preview-controls .left,
.rsa-preview-controls .right {
    @include flex;
    @include items-center;
}

.headlines.clamp,
.descriptions.clamp {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

// Responsive
.mobile-hide {
    display: block;
}
.mobile-show {
    display: none;
}
@media (max-width: 1280px) {
    .mobile-hide {
        display: none;
    }
    .mobile-show {
        display: block;
    }
    .google-serp-container-desktop .tabs .tab.selected {
        margin-left: 73px;
    }
    .google-serp-container-desktop .content-container {
        margin-left: 112px;
    }
}

@media (min-height: 1300px) {
    .google-serp-outer-container {
        position: sticky;
        top: 1.5rem;
        padding: 0.125rem;
        z-index: 2;
        width: calc(100% + 0.375rem);
        transform: translateX(-0.1875rem);
    }
    .google-serp-outer-container::before {
        content: '';
        @include absolute;
        background: rgba(255, 255, 255, 0.6);
        top: -2rem;
        left: 0;
        width: 100%;
        height: 4rem;
        z-index: -1;
        backdrop-filter: blur(0.5rem);
    }
}
</style>
