export type ImprovementStatistic = { key: string; value: string; title: string }
import type { ImprovementList, Improvement } from '@opteo/types'

export interface EnhancedImprovement extends Improvement.Object {
    preferences?: ImprovementList.RecActionPreferences
}

export interface ToolProvement {
    static_title: string
    toolType: 'ngram' // only ngram for now, but could be more in the future
    priority: number
    location: Improvement.AccountLocation[]
    created: string // ISO string
    requires_adjust: true // set to true so that batching is disabled
}

export function isToolProvement(pet: ToolProvement | EnhancedImprovement): pet is ToolProvement {
    return (pet as ToolProvement).toolType !== undefined
}

export const DismissDuration = {
    week: 'week',
    month: 'month',
    forever: 'forever',
} as const
export type DismissDuration = (typeof DismissDuration)[keyof typeof DismissDuration]
