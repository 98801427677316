// global imports
import { watch, inject, computed, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useLocalStorage } from '@vueuse/core'
import stableStringify from 'json-stable-stringify'

import orderBy from 'lodash-es/orderBy'
import cloneDeep from 'lodash-es/cloneDeep'
import uniq from 'lodash-es/uniq'
import { RsaWriter, Targets, Tracking } from '@opteo/types'

// local imports
import { buildSerpPreviewUrl } from '@/lib/serp'
import { promiseDebounce } from '@/lib/globalUtils'
import { useDomain } from '@/composition/domain/useDomain'
import { useUser } from '@/composition/user/useUser'
import { ProvideKeys } from '@/composition/useProvide'
import { delay } from '@/lib/globalUtils'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { Routes } from '@/router/routes'

// components-next
import { showAsyncPush } from '@opteo/components-next'
import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'
import { useTracking } from '@/composition/api/useTracking'
import { useOnboardingTour } from '@/composition/user/useOnboardingTour'
import { useAccount } from '@/composition/account/useAccount'

const MIN_SAVING_SPINNER_TIME = 1000
const DEFAULT_SEARCH_TERM = 'search query'

type State =
    | 'open-campaign'
    | 'open-ad-group'
    | 'open-rsa'
    | 'write-rsa'
    | 'edit-rsa'
    | 'preview-rsa'

type OTableRow = {
    type: 'campaign' | 'ad-group'
    name: string
    id: number
    adScore: RsaWriter.AdScore
    hasDrafts: boolean
    cost: number
    conversions: number
    conversionsValue: number
    cpa: number
    roas: number
}

type RsaStatistic = {
    key: string
    donutChart: {
        items: { y: number; label: string }[]
        metric: { label: 'Count'; dataType: 'number' }
    }
    table: {
        headers: RsaStatisticsTableHeader[]
        items: {
            id: string
            type: string
            count: number
            cost: number
            averageCtr: number
            averageCpi: string
        }[]
    }
}

type RsaStatisticsTableHeader = {
    key: string
    text: string
    sortable: boolean
    width?: number
    noPadding?: boolean
}

/**
 *
 * RSA selection flow
 * Campaign => AdGroup => RSA
 */
export function useRsaWriter() {
    const { trackAction } = useTracking()
    const { accountId } = useAccount()
    const { domainName, domainColour, domainInitials, domainId, performanceMode } = useDomain()
    const { userId } = useUser()
    const domainCurrency = inject<string>(ProvideKeys.CurrencyCode)

    const {
        data: rsaSelectionData,
        loading: selectorLoading,
        progressStep,
        mutate: refreshRsaSelection,
    } = useAPI<RsaWriter.RsaSelectionData>(Endpoint.GetRsaSelectionData, {
        uniqueId: () => domainId.value,
        waitFor: () => domainId.value,
        pollForProgress: true,
    })

    const { dismiss: dismissTour, modalVisible: showCopyOnboarding } = useOnboardingTour({
        stepName: 'tour_rsa',
    })

    const { currentRoute, push } = useRouter()

    const intercom = useIntercom()

    const routeParams = computed(() => currentRoute.value.params)

    const campaignId = computed(() =>
        routeParams.value.campaignId ? +(routeParams.value.campaignId as string) : undefined
    )
    const adGroupId = computed(() =>
        routeParams.value.adGroupId ? +(routeParams.value.adGroupId as string) : undefined
    )
    const adId = computed(() =>
        routeParams.value.adId ? +(routeParams.value.adId as string) : undefined
    )
    const draftId = computed(() =>
        routeParams.value.draftId ? (routeParams.value.draftId as string) : undefined
    )

    const currentCampaign = computed(() => {
        return rsaSelectionData.value?.campaigns.find(
            c => extractIdFromResourceName(c.resourceName) === campaignId.value
        )
    })

    const currentAdGroup = computed(() => {
        return rsaSelectionData.value?.campaigns
            .find(c => extractIdFromResourceName(c.resourceName) === campaignId.value)
            ?.adGroups.find(a => extractIdFromResourceName(a.resourceName) === adGroupId.value)
    })

    const campaignName = computed(() => currentCampaign.value?.name ?? '')
    const adGroupName = computed(() => currentAdGroup.value?.name ?? '')

    const currentState = computed<State>(() => {
        if (currentRoute.value.name === Routes.ToolkitRsaWriterAdEdit) {
            return 'edit-rsa'
        }

        if (currentRoute.value.name === Routes.ToolkitRsaWriterAdNew) {
            return 'write-rsa'
        }

        if (
            currentRoute.value.name === Routes.ToolkitRsaWriterAdEditPreview ||
            currentRoute.value.name === Routes.ToolkitRsaWriterAdNewPreview
        ) {
            return 'preview-rsa'
        }

        if (rsaRows.value) {
            return 'open-rsa'
        }

        if (adGroupRows.value) {
            return 'open-ad-group'
        }

        return 'open-campaign'
    })

    // Used to disable the final "Push" button when the user is previewing a new RSA and max RSAs exist
    const isPreviewingNewRsa = computed(
        () => currentRoute.value.name === Routes.ToolkitRsaWriterAdNewPreview
    )

    const sortRowsByItems =
        performanceMode.value === Targets.PerformanceMode.CPA
            ? [
                  { value: 'adScore', label: 'AdScore' },
                  { value: 'name', label: 'Name' },
                  { value: 'cost', label: 'Cost' },
                  { value: 'conversions', label: 'Conv.' },
                  { value: 'cpa', label: 'CPA' },
              ]
            : [
                  { value: 'adScore', label: 'AdScore' },
                  { value: 'name', label: 'Name' },
                  { value: 'cost', label: 'Cost' },
                  { value: 'conversionsValue', label: 'Value' },
                  { value: 'roas', label: 'ROAS' },
              ]

    const sortRowsBy = useLocalStorage('RsaRowsSortingKey', 'cost')

    // works for campaigns, adgroups, ads
    const extractIdFromResourceName = (resourceName: string): number => {
        const end = resourceName.split('/').slice(-1)[0]
        return end.includes('~') ? +end.split('~')[1] : +end
    }

    const wrangleEntity = (
        entity: RsaWriter.RsaSelectionRow,
        type: 'campaign' | 'ad-group'
    ): OTableRow => {
        return {
            type,
            name: entity.name,
            id: extractIdFromResourceName(entity.resourceName),
            cost: entity.metrics.cost,
            conversions: entity.metrics.conversions,
            conversionsValue: entity.metrics.conversionsValue,
            get cpa() {
                return this.cost ? this.cost / this.conversions : 0
            },
            get roas() {
                return this.conversionsValue ? this.conversionsValue / this.cost : 0
            },
            adScore: entity.adScore,
            hasDrafts: !!rsaSelectionData.value?.drafts.some(draft => {
                // hasDrafts if some of the adgroups in this campaign have this draft
                if (type === 'campaign') {
                    return rsaSelectionData.value?.campaigns
                        .find(c => c.resourceName === entity.resourceName)
                        ?.adGroups.some(ag => {
                            return draft.adGroupId === extractIdFromResourceName(ag.resourceName)
                        })
                }

                // hasDrafts if this adgroup has this draft
                if (type === 'ad-group') {
                    return draft.adGroupId === extractIdFromResourceName(entity.resourceName)
                }
            }),
        }
    }

    const sorter = (entity: OTableRow) => {
        if (sortRowsBy.value === 'adScore') return entity.adScore.total
        if (sortRowsBy.value === 'name') return entity.name
        if (sortRowsBy.value === 'cost') return -entity.cost
        if (sortRowsBy.value === 'conversions') return -entity.conversions
        if (sortRowsBy.value === 'conversionsValue') return -entity.conversionsValue
        if (sortRowsBy.value === 'cpa') return -entity.cpa
        if (sortRowsBy.value === 'roas') return -entity.roas
    }

    const campaignRows = computed(() => {
        return orderBy(
            (rsaSelectionData.value?.campaigns || []).map(campaign => {
                return wrangleEntity(campaign, 'campaign')
            }),
            row => sorter(row)
        )
    })

    const adGroupRows = computed(() => {
        const matchingCampaignRow = rsaSelectionData.value?.campaigns.find(
            campaign => extractIdFromResourceName(campaign.resourceName) === campaignId.value
        )

        if (!matchingCampaignRow) {
            return
        }

        return orderBy(
            matchingCampaignRow.adGroups.map(adGroup => {
                return wrangleEntity(adGroup, 'ad-group')
            }),
            row => sorter(row)
        )
    })

    const draftRows = computed(() => {
        if (!adGroupId.value) {
            return []
        }

        return (
            rsaSelectionData.value?.drafts
                .filter(draft => draft.adGroupId === adGroupId.value && !draft.baseAdId)
                .map(draft => {
                    return {
                        id: draft.id,
                        ...draft.draftFields,
                        urlBase: rsaSelectionData.value?.urlBase,
                    }
                }) ?? []
        )
    })

    const rsaRows = computed<RsaWriter.RsaAd[] | undefined>(() => {
        return currentAdGroup.value?.rsas.map(rsa => {
            const id = extractIdFromResourceName(rsa.resourceName)
            const matchingDraft = rsaSelectionData.value?.drafts.find(
                draft => draft.baseAdId === id
            )

            return {
                ...rsa,
                ...matchingDraft?.draftFields,
                id,
                hasDraft: !!matchingDraft,
            }
        })
    })

    const etaRows = computed<RsaWriter.EtaAd[] | undefined>(() => {
        return currentAdGroup.value?.etas
    })

    // Gads enforces a limit of 3 enabled RSAs per ad group
    const maxRsasAlreadyExist = computed(
        () =>
            (rsaRows.value ?? []).filter(r => r.status === RsaWriter.RsaStatus.ENABLED).length >= 3
    )

    const handleBreadcrumb = (level: 'account' | 'campaign' | 'adGroup') => {
        if (level == 'account') {
            push({ name: Routes.ToolkitRsaWriter })
        }
        if (level == 'campaign') {
            push({ name: Routes.ToolkitRsaWriterCampaign })
        }
        if (level == 'adGroup') {
            push({ name: Routes.ToolkitRsaWriterAdGroup })
        }
    }

    const openCampaign = (campaignId: number) => {
        push({
            name: Routes.ToolkitRsaWriterCampaign,
            params: { campaignId },
        })
    }

    const openAdGroup = (adGroupId: number) => {
        push({
            name: Routes.ToolkitRsaWriterAdGroup,
            params: { adGroupId },
        })
    }

    const launchWriterNew = (draftId?: string) => {
        if (!draftId) {
            draftId = `draft_${Math.random().toString(36).slice(2, 9)}`
        }
        push({
            name: Routes.ToolkitRsaWriterAdNew,
            params: { draftId },
        })
    }

    const launchWriterEdit = (adId: number) => {
        push({
            name: Routes.ToolkitRsaWriterAdEdit,
            params: { adId },
        })
    }

    const openPreview = () => {
        const formHasErrors = !sidebarRef.value.submitForm()
        if (formHasErrors) {
            return
        }

        if (!workingRsa.value) {
            throw new Error('workingRsa.value is undefined')
        }

        // Trim away any empty headlines or descriptions
        workingRsa.value.headlines = workingRsa.value?.headlines?.filter(h => h.text.trim() !== '')
        workingRsa.value.descriptions = workingRsa.value?.descriptions?.filter(
            h => h.text.trim() !== ''
        )

        if (adId.value) {
            push({
                name: Routes.ToolkitRsaWriterAdEditPreview,
            })
        } else {
            push({
                name: Routes.ToolkitRsaWriterAdNewPreview,
            })
        }
    }

    const publishRsa = async () => {
        if (!workingRsa.value || !workingRsa.value.draftId) {
            throw new Error('No working RSA (or draftId) to publish')
        }

        // Show loading blob at the top-right of the viewport
        const toast = showAsyncPush({
            hideAfterSuccessMs: 6000,
        })

        // "Lock in" these variables so that the "Edit RSA" of the error state button has the correct state to work with,
        // even after we've navigated away from the page and lost our current routeParams.
        const isNewRsa = !adId.value
        const _campaignId = campaignId.value
        const _adGroupId = adGroupId.value
        const _draftId = workingRsa.value.draftId
        const _adId = adId.value

        await delay(140) // Give it a beat before going back to the campaign list
        push({ name: Routes.ToolkitRsaWriter })

        const fields = extractEditableFields(workingRsa.value)

        try {
            await authRequest(Endpoint.PublishRsa, {
                body: {
                    adGroupId: adGroupId.value,
                    fields,
                    draftId: workingRsa.value.draftId,
                    baseAdId: adId.value,
                },
            })
            toast.showSuccessState()

            await deleteDraft(_draftId)

            // This will take some time, so new RSAs might "pop in" if the user
            // opens an ad group before the data has been refreshed.
            refreshRsaSelection()

            trackAction(
                isNewRsa ? Tracking.ActionName.CreatedRSA : Tracking.ActionName.UpdatedRSA,
                {
                    adGroupId: _adGroupId,
                    campaignId: _campaignId,
                    adId: _adId,
                    fields,
                }
            )

            intercom.trackEvent('push_rsa', { domain_id: domainId })
        } catch (e: any) {
            toast.showErrorState({
                longErrorMessage: e.message,
                buttons: [
                    {
                        label: 'Edit RSA',
                        handler: () => {
                            // Take user back to the Edit RSA page
                            const _routeParams = { campaignId: _campaignId, adGroupId: _adGroupId }

                            if (isNewRsa) {
                                push({
                                    name: Routes.ToolkitRsaWriterAdNew,
                                    params: { ..._routeParams, draftId: _draftId },
                                })
                            } else {
                                push({
                                    name: Routes.ToolkitRsaWriterAdEdit,
                                    params: { ..._routeParams, adId: _adId },
                                })
                            }

                            toast.close()
                        },
                    },
                ],
            })
        }
    }

    const transitionName = ref('section-next')
    const saving = ref(false)
    const showKeywordPopout = ref(false)

    watch(currentState, (newVal, oldVal) => {
        const ordering: State[] = [
            'open-campaign',
            'open-ad-group',
            'open-rsa',
            'edit-rsa',
            'write-rsa',
            'preview-rsa',
        ]

        // special case for when going from preview back to campaigns
        if (oldVal === 'preview-rsa' && newVal === 'open-campaign') {
            transitionName.value = 'section-next'
            return
        }

        const currentIndex = ordering.indexOf(oldVal)
        const nextIndex = ordering.indexOf(newVal)

        if (currentIndex > nextIndex) {
            transitionName.value = 'section-prev'
        } else {
            transitionName.value = 'section-next'
        }
    })

    async function updateAdStatus(
        status: RsaWriter.RsaStatus,
        ad: RsaWriter.RsaAd | RsaWriter.EtaAd,
        type: 'rsa' | 'eta'
    ) {
        await authRequest(Endpoint.SetAdStatus, {
            body: {
                resource_name: ad.resourceName,
                status,
            },
        })

        const adGroup = rsaSelectionData.value?.campaigns
            .find(campaign => campaign.resourceName === ad.campaignResourceName)
            ?.adGroups.find(adGroup => adGroup.resourceName === ad.adGroupResourceName)

        const baseAd =
            type === 'rsa'
                ? adGroup?.rsas.find(_rsa => _rsa.resourceName === ad.resourceName)
                : adGroup?.etas.find(eta => eta.resourceName === eta.resourceName)

        if (!baseAd) {
            throw new Error('Could not find ad in rsaSelectionData')
        }

        baseAd.status = status

        trackAction(Tracking.ActionName.SetAdStatus, { resourceName: ad.resourceName, status })
    }

    /**
     *
     * RSA Writing flow
     * Writing new RSAs and editing existing ones
     */

    const originalRsa = ref<RsaWriter.RsaAd>()
    const workingRsa = ref<Partial<RsaWriter.RsaAd> & { draftId: string }>()
    const isNewDraft = ref(false)

    const rsaBeingEdited = computed(() => {
        const rsasFlattened = rsaSelectionData.value?.campaigns
            .map(campaign => {
                return campaign.adGroups
                    .map(adGroup => {
                        return adGroup.rsas
                    })
                    .flat()
            })
            .flat()

        return rsasFlattened?.find(
            rsa => extractIdFromResourceName(rsa.resourceName) === adId.value
        )
    })

    // The RSA specified in the URL params does not exist in the response we got from the backend.
    // Could happen if the URL is mistyped (or from an old link), or if the backend response is out of sync with due to cache.
    const rsaDoesNotExist = computed(() => {
        if (!rsaSelectionData) {
            return false
        }

        const campaignMissing = campaignId.value && !currentCampaign.value
        const adGroupMissing = adGroupId.value && !currentAdGroup.value
        const rsaMissing = adId.value && !rsaBeingEdited.value

        return campaignMissing || adGroupMissing || rsaMissing
    })

    // Other RSAs in AdGroup besides the currently open one
    const existingRsasInAdgroup = computed(() => {
        return rsaRows.value?.filter(
            rsa => extractIdFromResourceName(rsa.resourceName) !== adId.value
        )
    })

    watch(currentState, newState => {
        if (rsaSelectionData.value) {
            initWorkingRsa()
        }
    })

    watch(rsaSelectionData, (newVal, oldVal) => {
        if (!oldVal && newVal) {
            initWorkingRsa()
        }
    })

    function initWorkingRsa() {
        if (currentState.value == 'write-rsa') {
            if (!draftId.value) {
                throw new Error('No draftId initialised, cannot initialise working RSA')
            }
            originalRsa.value = undefined //reset originalRsa, which should not exist

            const matchingDraft = rsaSelectionData.value?.drafts.find(
                draft => draft.id === draftId.value
            )

            if (matchingDraft) {
                workingRsa.value = cloneDeep({
                    draftId: draftId.value,
                    ...matchingDraft.draftFields,
                    urlBase: rsaSelectionData.value?.urlBase,
                })
            } else {
                workingRsa.value = {
                    draftId: draftId.value,
                    pathOne: '',
                    pathTwo: '',
                    urlBase: rsaSelectionData.value?.urlBase,
                    headlines: Array.from(Array(4)).map(() => ({ text: '', pinnedTo: 0 })),
                    descriptions: Array.from(Array(4)).map(() => ({ text: '', pinnedTo: 0 })),
                }

                isNewDraft.value = true
            }
        }

        if (currentState.value == 'edit-rsa' && rsaBeingEdited.value) {
            const rsa = rsaBeingEdited.value

            originalRsa.value = cloneDeep(rsa)

            const matchingDraft = rsaSelectionData.value?.drafts.find(
                draft =>
                    draft.baseAdId === extractIdFromResourceName(rsa.resourceName) &&
                    draft.adGroupId === adGroupId.value
            )

            if (matchingDraft) {
                workingRsa.value = cloneDeep({
                    draftId: rsa.draftId,
                    ...matchingDraft.draftFields,
                    urlBase: rsaSelectionData.value?.urlBase,
                })
            } else {
                workingRsa.value = cloneDeep(rsa)

                isNewDraft.value = true
            }
        }

        // if (currentState.value == 'edit-rsa' && !rsaBeingEdited.value) {
        //     throw new Error('RSA not found')
        // }
    }

    const rsaUpdated = (newRsa: RsaWriter.RsaAd) => {
        workingRsa.value = newRsa
    }

    /**
     * Converting ETAs to RSA drafts
     */

    const convertEtaToDraft = async (eta: RsaWriter.EtaAd) => {
        const draftId = `draft_${Math.random().toString(36).slice(2, 9)}`
        const draftFields = extractEditableFields(eta)

        rsaSelectionData.value?.drafts.push({
            id: draftId,
            adGroupId: extractIdFromResourceName(eta.adGroupResourceName),
            draftFields,
        })

        launchWriterNew(draftId)

        await saveDraftDebounced(draftId, draftFields)
    }

    /**
     * Form stuff
     */

    const sidebarRef = ref()

    const extractEditableFields = (
        rsa: Partial<RsaWriter.RsaAd>
    ): RsaWriter.EditableRsaAdFields => {
        return {
            pathOne: rsa.pathOne ?? '',
            pathTwo: rsa.pathTwo ?? '',
            headlines: rsa.headlines ?? [],
            descriptions: rsa.descriptions ?? [],
            finalUrl: rsa.finalUrl ?? '',
            finalUrlMobile: rsa.finalUrlMobile ?? '',
            finalUrlSuffix: rsa.finalUrlSuffix ?? '',
            trackingUrlTemplate: rsa.trackingUrlTemplate ?? '',
        }
    }

    const formIsEmpty = computed(() => {
        if (!workingRsa.value) {
            return true
        }
        const editableFields = extractEditableFields(workingRsa.value)
        const stringFields = [
            'pathOne',
            'pathTwo',
            'finalUrl',
            'finalUrlMobile',
            'finalUrlSuffix',
            'trackingUrlTemplate',
        ]

        return Object.entries(editableFields).every(([key, value]) => {
            if (stringFields.includes(key)) {
                return value === ''
            }

            if (Array.isArray(value)) {
                return value.every(item => item.text === '' && item.pinnedTo === 0)
            }

            return true
        })
    })

    const copyRsaToForm = (rsa: RsaWriter.RsaAd) => {
        if (!workingRsa.value) {
            throw new Error('Cannot copy to un-initialised workingRsa')
        }
        workingRsa.value = {
            ...workingRsa.value,
            ...extractEditableFields(cloneDeep(rsa)),
        }

        fieldsChanged()
    }

    const fieldsChanged = async () => {
        if (!workingRsa.value || !rsaSelectionData.value || !adGroupId.value) {
            throw new Error('Must have an in-progress RSA to save draft')
        }

        const draftFields = extractEditableFields(workingRsa.value)

        let matchingDraft = rsaSelectionData.value.drafts.find(
            draft => draft.id === workingRsa.value?.draftId
        )

        if (matchingDraft) {
            matchingDraft.draftFields = draftFields
        } else {
            rsaSelectionData.value?.drafts.push({
                id: workingRsa.value.draftId!,
                adGroupId: adGroupId.value,
                baseAdId: adId.value,
                draftFields,
            })
        }

        saving.value = true
        await saveDraftDebounced(workingRsa.value.draftId, draftFields)
        saving.value = false

        isNewDraft.value = false
    }

    /**
     *
     * Draft saving/deleting
     */

    // Existing RSA draft has no changes made.
    const noChangesInDraft = computed(() => {
        return (
            adId.value && // We're editing an existing ad
            rsaBeingEdited.value && // should always be true if adId is set
            workingRsa.value && // should always be true if adId is set
            stableStringify(extractEditableFields(workingRsa.value)) ===
                stableStringify(extractEditableFields(rsaBeingEdited.value))
        )
    })

    const saveDraftDebounced = promiseDebounce(
        async (_draftId: string, draftFields: RsaWriter.EditableRsaAdFields) => {
            // If we're saving a draft attached to an existing ad and they're the same, delete the draft
            if (
                adId.value &&
                rsaBeingEdited.value &&
                stableStringify(draftFields) ===
                    stableStringify(extractEditableFields(rsaBeingEdited.value))
            ) {
                return deleteDraft(_draftId)
            }

            await authRequest(Endpoint.SaveDraft, {
                body: {
                    draftId: _draftId,
                    adGroupId: currentRoute.value.params.adGroupId,
                    draftFields,
                    baseAdId: currentRoute.value.params.adId,
                },
            })

            if (isNewDraft.value) {
                intercom.trackEvent('create_rsa_draft', { domain_id: domainId.value })
            }
        },
        MIN_SAVING_SPINNER_TIME
    )

    const deleteDraft = async (draftId: string) => {
        if (!rsaSelectionData.value) {
            throw new Error('No RSA selection data')
        }

        await authRequest(Endpoint.DeleteDraft, {
            body: { draftId },
        })

        rsaSelectionData.value.drafts = rsaSelectionData.value.drafts.filter(
            draft => draft.id !== draftId
        )
    }

    /* Converting search terms table */

    const recommendedSearchTermsRows = computed<RsaWriter.OTableSearchTermRow[]>(() => {
        const allRsaText = [
            workingRsa.value?.headlines?.map(h => h.text),
            workingRsa.value?.descriptions?.map(d => d.text),
        ]
            .flat()
            .join(' ')
            .toLowerCase()

        if (
            !rsaSelectionData.value || // Root data not loaded yet
            !currentCampaign.value || // Not entered campaign yet
            !workingRsa.value // We're not editing/writing an RSA
        ) {
            return []
        } else {
            return (
                rsaSelectionData.value.searchTermsPerCampaign[
                    currentCampaign.value.resourceName
                ]?.map(searchTerm => {
                    return { ...searchTerm, includedInRsa: allRsaText.includes(searchTerm.text) }
                }) ?? []
            )
        }
    })

    /*
        Top Search Terms or Keywords, used for 
        - ad preview (top 1)
        - competitor ads (top 5)
        - serp preview (top 1)

        We tack on keywords at the end of this array to avoid a situation where there are no search terms.
    */

    const topImpressionSearchTerms = computed<string[]>(() => {
        const searchTerms =
            rsaSelectionData.value?.topSearchTermsPerAdGroup[
                currentAdGroup.value?.resourceName ?? ''
            ]?.map(searchTerm => searchTerm.text) ?? []

        const keywords = currentAdGroup.value?.keywords?.map(k => k.rawText) ?? []

        return uniq([...searchTerms, ...keywords])
    })

    const topImpressionSearchTerm = computed(
        () => topImpressionSearchTerms.value[0] ?? DEFAULT_SEARCH_TERM
    )

    /**
     * Competitor ads
     */

    const searchTermsForCompetitorAds = computed(() => {
        if (!rsaSelectionData.value) {
            return
        }

        return topImpressionSearchTerms.value.slice(0, 5).map(searchTerm => searchTerm)
    })

    const { data: competitorAds } = useAPI<RsaWriter.CompetitorAd>(Endpoint.GetCompetitorAds, {
        waitFor: () => searchTermsForCompetitorAds.value,
        body: () => ({
            search_terms: searchTermsForCompetitorAds.value,
        }),
        uniqueId: () => `${domainId.value}:${JSON.stringify(searchTermsForCompetitorAds.value)}`,
    })

    /*
        SERP preview
    */

    const serpAvailable = computed(() => {
        // available if topImpressionSearchTerm exists but is the default
        return !(topImpressionSearchTerm.value === DEFAULT_SEARCH_TERM)
    })

    const buildSerpImageUrl = () => {
        if (!searchTermsForCompetitorAds.value) {
            return
        }

        if (!searchTermsForCompetitorAds.value.length) {
            return
        }

        const keyword = searchTermsForCompetitorAds?.value[0]

        return buildSerpPreviewUrl({
            searchTerm: keyword,
            accountId: accountId.value,
        })
    }

    /**
     *
     * AI ads from Writesonic
     */

    const {
        data: writeSonicAssets,
        loading: aiAssetsLoading,
        error: aiAssetsError,
    } = useAPI<RsaWriter.WritesonicAssets>(Endpoint.GenerateAiRsaContent, {
        waitFor: () =>
            (currentState.value === 'edit-rsa' || currentState.value === 'write-rsa') &&
            rsaSelectionData.value &&
            adGroupId.value,
        body: () => ({ adGroupId: adGroupId.value }),
        uniqueId: () => `${adGroupId.value}`,
    })

    const aiHeadlineAssets = computed(() => writeSonicAssets.value?.headlines ?? [])
    const aiDescriptionAssets = computed(() => writeSonicAssets.value?.descriptions ?? [])

    /**
     *
     * Misc
     */

    const handleBackButton = async () => {
        /*
            This function is a bit confusing, but the idea is to go back to the previous state,
            at the same track as the current state (aka "new" vs "edit")
        */
        const orderedRoutes: string[][] = [
            [Routes.ToolkitRsaWriter],
            [Routes.ToolkitRsaWriterCampaign],
            [Routes.ToolkitRsaWriterAdGroup],
            [Routes.ToolkitRsaWriterAdNew, Routes.ToolkitRsaWriterAdEdit],
            [Routes.ToolkitRsaWriterAdNewPreview, Routes.ToolkitRsaWriterAdEditPreview],
        ]

        const index = orderedRoutes.findIndex(routeNames =>
            routeNames.includes(currentRoute.value.name as string)
        )

        const subIndex = orderedRoutes[index].findIndex(
            routeName => currentRoute.value.name === routeName
        )

        let destinationRoute = orderedRoutes[index - 1][subIndex] ?? orderedRoutes[index - 1][0]

        push({ name: destinationRoute })
    }

    onMounted(() => {
        // should not start with the preview page, so send them back
        if (currentRoute.value.name === Routes.ToolkitRsaWriterAdNewPreview) {
            launchWriterNew(draftId.value)
        }

        if (currentRoute.value.name === Routes.ToolkitRsaWriterAdEditPreview) {
            if (!adId.value) {
                throw new Error('No adId in ToolkitRsaWriterAdEditPreview')
            }
            launchWriterEdit(adId.value)
        }

        if (rsaSelectionData.value) {
            initWorkingRsa()
        }
    })

    const getAssetStats = (level: 'campaign' | 'account') => {
        if (!rsaSelectionData.value) {
            return
        }

        const {
            accountLevel: accountLevelRsaAssetStats,
            campaignLevel: campaignLevelRsaAssetStats,
        } = rsaSelectionData.value.rsaAssetStats

        const matchingCampaignStats = Object.entries(campaignLevelRsaAssetStats).find(
            ([campaign]) => extractIdFromResourceName(campaign) === campaignId.value
        )

        const assetsStats =
            matchingCampaignStats && level === 'campaign'
                ? matchingCampaignStats[1]
                : accountLevelRsaAssetStats

        const headlineAssets = orderBy(assetsStats.headlines, ({ score }) => score, 'desc')
        const descriptionAssets = orderBy(assetsStats.descriptions, ({ score }) => score, 'desc')

        return { headlineAssets, descriptionAssets }
    }

    const campaignHeadlineAssets = computed(() => getAssetStats('campaign')?.headlineAssets)
    const campaignDescriptionAssets = computed(() => getAssetStats('campaign')?.descriptionAssets)

    const accountHeadlineAssets = computed(() => getAssetStats('account')?.headlineAssets)
    const accountDescriptionAssets = computed(() => getAssetStats('account')?.descriptionAssets)

    const keywordsItems = computed(() => {
        return currentAdGroup.value?.keywords.map(keyword => {
            const { impressions, clicks, conversions, conversionsValue } = keyword.metrics

            const ctr = clicks / impressions || 0

            return {
                id: keyword.resourceName,
                keyword: keyword.text,
                impressions,
                clicks,
                ctr,
                conversions,
                conversionsValue,
            }
        })
    })

    const isUsingCpa = computed(() => performanceMode.value === Targets.PerformanceMode.CPA)

    const keywordsHeaders = [
        { key: 'keyword', text: 'Keyword', sortable: true },
        { key: 'impressions', text: 'Impr.', sortable: true, width: 110, noPadding: true },
        { key: 'clicks', text: 'Clicks', sortable: true, width: 100, noPadding: true },
        { key: 'ctr', text: 'CTR', sortable: true, width: 114, noPadding: true },
        isUsingCpa.value
            ? { key: 'conversions', text: 'Con.', sortable: true, width: 88, noPadding: true }
            : {
                  key: 'conversionsValue',
                  text: 'Val.',
                  sortable: true,
                  width: 88,
                  noPadding: true,
              },
    ]

    const sortGroupsByItems = [
        { value: 'adScore', label: 'AdScore' },
        { value: 'name', label: 'Name' },
        { value: 'cost', label: 'Cost' },
        { value: 'conversions', label: 'Conv.' },
        { value: 'conversionsValue', label: 'Value' },
        { value: 'cpa', label: 'CPA' },
        { value: 'roas', label: 'ROAS' },
    ]
    const sortGroupsBy = ref('adScore')
    const handleRsaWriterClose = () => {
        push({ name: Routes.Toolkit })
    }

    const addBodyNoScroll = () => {
        document.body.classList.add('no-scroll')
    }
    const removeBodyNoScroll = () => {
        document.body.classList.add('no-scroll')
    }

    const rsaStatistics = computed(() => {
        if (!rsaSelectionData.value) {
            return
        }

        const { adGroupLevel, rsaLevel } = rsaSelectionData.value.accountStats

        const joinedStats = Object.entries({ ...adGroupLevel, ...rsaLevel })

        const rsaStatistics: RsaStatistic[] = []

        joinedStats.forEach(([statBy, stats]) => {
            const isAdGroupLevel = Object.keys(adGroupLevel).includes(statBy)

            const typeHeader = isAdGroupLevel
                ? { key: 'type', text: 'Ad Groups With', sortable: true }
                : { key: 'type', text: 'RSAs With', sortable: true }

            const headers: RsaStatisticsTableHeader[] = [
                typeHeader,
                { key: 'count', text: 'Count', sortable: true, width: 120, noPadding: true },
                { key: 'cost', text: 'Cost', sortable: true, width: 140, noPadding: true },
                {
                    key: 'averageCtr',
                    text: 'Avg. CTR',
                    sortable: true,
                    width: 128,
                    noPadding: true,
                },
                {
                    key: 'averageCpi',
                    text: 'Avg. CPI',
                    sortable: true,
                    width: 128,
                    noPadding: true,
                },
            ]

            const rsaStatistic: RsaStatistic = {
                key: statBy,
                donutChart: {
                    items: [],
                    metric: {
                        label: 'Count',
                        dataType: 'number',
                    },
                },
                table: {
                    headers,
                    items: [],
                },
            }

            Object.entries(stats).forEach(([type, data]) => {
                data = data as RsaWriter.AccountStatValues

                const { donutChart, table } = rsaStatistic

                const { readableType, count, cost, clicks, impressions, conversions } = data

                if (count > 0) {
                    donutChart.items.push({ y: count, label: readableType })
                }

                table.items.push({
                    id: type,
                    type: readableType,
                    count,
                    cost,
                    averageCtr: clicks / impressions || 0,
                    averageCpi: (conversions / impressions || 0).toFixed(5),
                })
            })

            rsaStatistics.push(rsaStatistic)
        })

        return rsaStatistics
    })

    return {
        // Basics
        domainName,
        domainColour,
        domainInitials,
        domainCurrency,
        addBodyNoScroll,
        removeBodyNoScroll,
        performanceMode,
        isUsingCpa,

        // Main data blobs & derived computed vars
        rsaSelectionData,
        campaignRows,
        adGroupRows,
        rsaRows,
        etaRows,
        draftRows,
        workingRsa,
        originalRsa,
        rsaDoesNotExist,
        maxRsasAlreadyExist,
        existingRsasInAdgroup,
        campaignName,
        adGroupName,

        // State
        handleBackButton,
        handleRsaWriterClose,
        handleBreadcrumb,
        currentState,
        selectorLoading,
        transitionName,
        sortRowsByItems,
        sortRowsBy,
        progressStep,
        sortGroupsByItems,
        sortGroupsBy,
        isPreviewingNewRsa,

        // Assets & other inspiration bits
        accountHeadlineAssets,
        accountDescriptionAssets,
        campaignHeadlineAssets,
        campaignDescriptionAssets,
        keywordsHeaders,
        keywordsItems,
        rsaStatistics,
        recommendedSearchTermsRows,
        competitorAds,
        aiAssetsLoading,
        aiAssetsError,
        aiHeadlineAssets,
        aiDescriptionAssets,
        buildSerpImageUrl,
        serpAvailable,
        topImpressionSearchTerm,

        // Actions
        showKeywordPopout,
        showCopyOnboarding,
        openCampaign,
        openAdGroup,
        launchWriterNew,
        launchWriterEdit,
        convertEtaToDraft,
        openPreview,
        publishRsa,
        updateAdStatus,
        deleteDraft,
        copyRsaToForm,
        dismissTour,

        // Form stuff
        rsaUpdated,
        fieldsChanged,
        noChangesInDraft,
        formIsEmpty,
        saving,

        // Misc
        sidebarRef,
        RsaStatus: RsaWriter.RsaStatus,
    }
}
