<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="sectionType"
        :section-name="sectionName"
        :mode="mode"
    >
        <template #content>
            <div v-for="copy in sectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>

            <Spacer height="1.5rem" />

            <Text as="h6" weight="600">Capped vs. Uncapped</Text>
            <Spacer height="2rem" />
            <div class="donut-chart-container" :class="{ 'pdf-shadow-fix': isPdf }">
                <DonutChart
                    :show-keys="true"
                    :items="formatDonutChartData(details?.pie_data ?? [])"
                    :metric="{ label: 'Cost', dataType: 'percentage' }"
                />
            </div>

            <Spacer v-if="budgetCapTableItems?.length" height="2.5rem" />

            <Text v-if="budgetCapTableItems?.length" as="h6" weight="600">{{
                budgetCapTableItems.length > 3
                    ? `Top 3 Budget Capped Campaigns`
                    : `Total Budget Capped Campaigns`
            }}</Text>
            <Spacer v-if="budgetCapTableItems?.length" height="2rem" />

            <oTable
                v-if="budgetCapTableItems?.length"
                :headers="budgetCapTableHeaders"
                :items="budgetCapTableItems"
                :fixed-layout="true"
                :border-radius="20"
                responsive-mode="columns"
                responsive-breakpoint="767px"
            >
                <template #header.campaign>
                    <div class="campaign-table-header">Campaign</div>
                </template>
                <template #column.campaign="cellData">
                    <div class="column-overlay">
                        <Tooltip
                            :content="cellData.value.length >= 10 ? cellData.value : ''"
                            :offset="[0, 8]"
                            :max-width="700"
                            placement="top-start"
                            style="width: 100%"
                        >
                            <EntityPill
                                type="campaign"
                                :content="cellData.value"
                                :tooltip="false"
                            />
                        </Tooltip>
                    </div>
                </template>
                <template #column.cost="cellData">
                    <Money :currency="domainCurrency" :value="cellData.value" />
                </template>
                <template #column.cpa="cellData">
                    <Money :currency="domainCurrency" :value="cellData.value" />
                </template>
                <template #column.roas="cellData">
                    <Roas :value="cellData.value" />
                </template>
                <template #column.lost="cellData">
                    <Text as="span" size="f-8" color="red" weight="500">
                        <Percent :value="cellData.value" :decimal-places="2" />
                    </Text>
                </template>
            </oTable>

            <Spacer height="1.5rem" />

            <ImprovementNote>
                <Text size="f-9" color="gray">
                    Score weighted by cost. Data collected over the last <b>7 days</b>.
                </Text>
            </ImprovementNote>
        </template>
    </ScorecardSection>
</template>

<script lang="ts">
import { Scorecard } from '@opteo/types'

import {
    Text,
    Spacer,
    oTable,
    Money,
    Percent,
    Number as NumberVue,
    Roas,
    Tooltip,
    DonutChart,
    EntityPill,
} from '@opteo/components-next'

// Local imports
import SectionHidden from './SectionHidden.vue'
import { formatDonutChartData } from './utils'
import { getSectionCopy } from './utils'
import ScorecardSection from './ScorecardSection.vue'
import { defineComponent, computed, inject, PropType } from 'vue'

export default defineComponent({
    name: 'BudgetLimitedCampaigns',
    components: {
        Text,
        Spacer,
        DonutChart,
        SectionHidden,
        ScorecardSection,
        oTable,
        Money,
        Percent,
        Roas,
        Tooltip,
        EntityPill,
    },
    props: {
        score: {
            type: Number,
        },
        donutChart: {
            type: Object,
        },
        domainCurrency: {
            type: String,
        },
        invalid: {
            type: Boolean,
        },
        domainName: {
            type: String,
        },
        isLoading: {
            type: Boolean,
        },
        details: {
            type: Object as PropType<Scorecard.BudgetCapScoreDetails>,
        },
        isUsingCpa: {
            type: Boolean,
            default: true,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
    },

    setup(props) {
        const isPdf = props.mode === 'pdf'

        const budgetCapTableHeaders = computed(() => [
            { key: 'campaign', text: 'Campaign', noPadding: true },
            { key: 'cost', text: 'Cost', width: 86, noPadding: true },
            props.isUsingCpa
                ? { key: 'cpa', text: 'CPA', width: 82, noPadding: true }
                : { key: 'roas', text: 'ROAS', width: 82, noPadding: true },
            { key: 'lost', text: 'Lost IS', width: 80, noPadding: true },
        ])

        const budgetCapTableItems = computed(() => {
            return props.details?.top_capped_campaigns.map(campaign => {
                return {
                    id: campaign.name,
                    campaign: campaign.name,
                    cost: campaign.cost,
                    cpa: campaign.cost ? campaign.cost / campaign.conversions : 0,
                    roas: campaign.conversions_value
                        ? campaign.conversions_value / campaign.cost
                        : 0,
                    lost: campaign.impression_share_lost,
                }
            })
        })

        const textOptions = {
            0: [
                `Several campaigns in your account are limited by budget. These campaigns could perform better with some adjustments. Budget limited campaigns often indicate a need to adjust bids or targets.`,
                `Depending on a deeper dive of the data, we would recommend either reducing campaign bids to generate more cost-efficient clicks, or boosting daily budgets to increase conversion volume.`,
            ],
            70: [
                `A few campaigns in your account are limited by budget. These campaigns could perform better with some simple adjustments. Budget limited campaigns often indicate a need to adjust bidding strategies. This should help generate cost-efficient conversions.`,
                `Depending on a deeper dive of the data, we would recommend either reducing campaign bids to generate more cost-efficient clicks, or boosting daily budgets to increase conversion volume.`,
            ],
            99: [
                `No campaigns in your account are limited by budget, this means your ads are competing for all available traffic. We recommend continuing to balance bids and budgets as your account grows.`,
            ],
        }

        const sectionCopy = computed(() => {
            return getSectionCopy(textOptions, props.score ?? 0)
        })

        return {
            sectionType: Scorecard.SectionTypes.budgetCap,
            sectionName: Scorecard.SectionNames.budgetCap,
            textOptions,
            sectionCopy,
            budgetCapTableHeaders,
            budgetCapTableItems,
            formatDonutChartData,
            isPdf,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.donut-chart-container {
    @include container;
    @include br-20;
    @include pb-32;
}

.campaign-table-header {
    @include pl-24;
}
.column-overlay {
    max-width: 100%;
    height: 4.5rem;
    overflow: hidden;
    @include pl-24;
    @include flex;
    @include items-center;
}
.column-overlay::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}

:deep(.improvement-note) {
    @include br-20;
}

@media screen and (max-width: $mq-767-max) {
    .campaign-table-header {
        padding-left: unset;
    }
    .column-overlay {
        height: unset;
        padding-left: unset;
        overflow: unset;
    }
    .column-overlay::after {
        display: none;
    }
}
</style>
