import { computed, onMounted, ref, watch } from 'vue'
import { Endpoint, useAPI, authRequest } from '../api/useAPI'
import { useRoute, useRouter } from 'vue-router'
import { useUser } from '../user/useUser'
import { useDomain } from '../domain/useDomain'
import { showSnackbar } from '@opteo/components-next'
import { delay, scrollTo } from '@/lib/globalUtils'
import { useIntercom } from '@/lib/intercom/useIntercom'

import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

import { AnalyticsTypes } from '@opteo/types'

export function useAnalytics(beforeOauthRedirect: () => void) {
    const { groupId } = useUser()
    const { domainId } = useDomain()
    const route = useRoute()
    const { editUserAttributes } = useIntercom()
    const justLinked = ref(!!route.query.linked)

    const postGAOauthPageLoad = ref(route.query.from === 'ga')

    const displayError = (message: string) =>
        showSnackbar({
            message: message,
            timeout: 10000,
        })

    /*
        Show snackbar on error in URL when initialising
    */
    const authorizationError = route.query.err as string
    const userCancelledAuthorization = authorizationError === AnalyticsTypes.Error.ACCESS_DENIED

    if (postGAOauthPageLoad.value && authorizationError && !userCancelledAuthorization) {
        displayError(authorizationError)
    }

    /*
        Scroll to Analytics section when coming back from OAuth
    */
    const router = useRouter()

    onMounted(async () => {
        // strip URL query params to avoid duplicating behavior on page refresh
        router.replace({ query: {} })

        if (postGAOauthPageLoad.value) {
            await delay(1)
            scrollTo('analytics', 'auto', 96)
        }
    })

    /*
        Gather data
    */

    const { data: hasConnection, mutate: refreshHasConnection } = useAPI<boolean>(
        Endpoint.CheckAccountGAConnection,
        {
            waitFor: () => groupId.value && domainId.value,
            body: () => {
                return { group_id: groupId.value }
            },
            uniqueId: () => domainId.value,
        }
    )

    const { data: linkingDate } = useAPI<string>(Endpoint.GetGALinkingDate, {
        waitFor: () => hasConnection.value === true && groupId.value && domainId.value,
        body: () => {
            return { group_id: groupId.value }
        },
        uniqueId: () => domainId.value,
    })

    const formattedLinkingDate = computed(() => {
        if (!linkingDate.value) {
            return ''
        }
        return format(parseISO(linkingDate.value), `MMMM do y 'at' h:mmaaa`)
    })

    const {
        data: accountProfile,
        mutate: refreshAccountProfile,
        error: accountProfileError,
    } = useAPI<AnalyticsTypes.MatadataEntityBase>(Endpoint.GetUserProfileIDAndName, {
        waitFor: () => hasConnection.value === true && groupId.value,
        body: () => {
            return { group_id: groupId.value }
        },
        uniqueId: () => domainId.value,
    })

    const {
        data: availableProfiles,
        loading: availableProfilesLoading,
        mutate: mutateAvailableProfiles,
    } = useAPI<AnalyticsTypes.FormattedAccount>(Endpoint.GetAnalyticsProfiles, {
        waitFor: () => hasConnection.value === true && groupId.value,
        body: () => {
            return { group_id: groupId.value }
        },
        uniqueId: () => domainId.value,
    })

    const { data: gaConnections } = useAPI<{ connected_domains: number }>(
        Endpoint.GetAllGAGroupConnections,
        {
            waitFor: () => hasConnection.value === true && groupId.value && justLinked.value,
            body: () => {
                return { group_id: groupId.value }
            },
        }
    )

    const connectionStatusLoading = computed(
        () =>
            !(
                hasConnection.value === false ||
                (hasConnection.value === true && linkingDate.value && accountProfile.value)
            )
    )

    /* 
        Modal Control. More tricky than it looks.
    */
    const slidePreviewButtonShown = ref(false)
    const showPreviewModal = ref(false)
    const showModal = ref(false)
    const modalManuallyClosed = ref(false)

    // This fn opens the modal when the user has just connected GA, but has not yet chosen a profile.
    const shouldShowSelectorModal = computed(
        () => showModal.value || (justLinked.value && !modalManuallyClosed.value)
    )

    // shouldShowSelectorModal is a readOnly variable, so it cannot be used as a v-model. We use actuallyShowModal instead.
    const actuallyShowModal = ref(shouldShowSelectorModal.value)
    watch(shouldShowSelectorModal, newVal => (actuallyShowModal.value = newVal))

    const openViewSelector = () => {
        showModal.value = true
    }

    const closeViewSelector = () => {
        showModal.value = false
        modalManuallyClosed.value = true // keeps the modal closed despite `justLinked`
    }

    /*
        Handle profile selection 
    */

    const selectedEntities = ref<{
        account?: AnalyticsTypes.FormattedAccountData
        property?: AnalyticsTypes.FormattedAccountProperty
        profile?: AnalyticsTypes.MatadataEntityBase
    }>()

    const savingChanges = ref(false)

    const selectAnalyticsEntity = (
        entityType: AnalyticsTypes.MatadataEntityType,
        entityId: string
    ) => {
        switch (entityType) {
            case AnalyticsTypes.MatadataEntityType.Account:
                const account = availableProfiles.value?.accounts.find(({ id }) => id === entityId)
                const accountChanged = selectedEntities.value?.account?.id !== account?.id

                selectedEntities.value = {
                    ...selectedEntities.value,
                    ...(accountChanged && {
                        property: undefined,
                        profile: undefined,
                    }),
                    account,
                }

                filteredEntities.value = {
                    ...filteredEntities.value,
                    ...(accountChanged && {
                        properties: account?.properties,
                        profiles: undefined,
                    }),
                }

                break
            case AnalyticsTypes.MatadataEntityType.Property:
                const property = selectedEntities.value?.account?.properties.find(
                    ({ id }) => id === entityId
                )
                const propertyChanged = selectedEntities.value?.property?.id !== property?.id

                selectedEntities.value = {
                    ...selectedEntities.value,
                    ...(propertyChanged && { profile: undefined }),
                    property,
                }

                filteredEntities.value = {
                    ...filteredEntities.value,
                    ...(propertyChanged && { profiles: property?.profiles }),
                }

                break
            case AnalyticsTypes.MatadataEntityType.Profile:
                const profile = selectedEntities.value?.property?.profiles.find(
                    ({ id }) => id === entityId
                )
                selectedEntities.value = { ...selectedEntities.value, profile }
                break
            default:
                break
        }
    }
    /*
        Handle profile search 
    */

    const filteredEntities = ref<{
        accounts?: AnalyticsTypes.FormattedAccountData[]
        properties?: AnalyticsTypes.FormattedAccountProperty[]
        profiles?: AnalyticsTypes.MatadataEntityBase[]
    }>({ accounts: availableProfiles.value?.accounts })

    watch(
        () => availableProfiles.value,
        updatedProfiles => {
            filteredEntities.value = { accounts: updatedProfiles?.accounts }
        }
    )

    const searchAnalyticsEntities = (
        entityType: AnalyticsTypes.MatadataEntityType,
        searchQuery: string
    ) => {
        const searchRegex = new RegExp(searchQuery, 'i')

        switch (entityType) {
            case AnalyticsTypes.MatadataEntityType.Account:
                const allAccounts = availableProfiles.value?.accounts ?? []
                const filteredAccounts = allAccounts.filter(
                    ({ id, name }) => searchRegex.test(id) || searchRegex.test(name)
                )
                const accounts = !searchQuery ? allAccounts : filteredAccounts
                filteredEntities.value = { ...filteredEntities.value, accounts: [...accounts] }
                break
            case AnalyticsTypes.MatadataEntityType.Property:
                const allProperties = selectedEntities.value?.account?.properties ?? []
                const filteredProperties = allProperties.filter(
                    ({ id, name }) => searchRegex.test(id) || searchRegex.test(name)
                )
                const properties = !searchQuery ? allProperties : filteredProperties
                filteredEntities.value = { ...filteredEntities.value, properties: [...properties] }
                break
            case AnalyticsTypes.MatadataEntityType.Profile:
                const allProfiles = selectedEntities.value?.property?.profiles ?? []
                const filteredProfiles = allProfiles.filter(
                    ({ id, name }) => searchRegex.test(id) || searchRegex.test(name)
                )
                const profiles = !searchQuery ? allProfiles : filteredProfiles
                filteredEntities.value = { ...filteredEntities.value, profiles: [...profiles] }
                break
            default:
                break
        }
    }

    const cancelViewSelection = () => {
        selectedEntities.value = undefined
        filteredEntities.value = { accounts: availableProfiles?.value?.accounts }
        closeViewSelector()
    }

    const linkView = async () => {
        if (!selectedEntities.value?.profile?.id) {
            throw new Error('no profile selected, cannot save')
        }

        closeViewSelector()
        savingChanges.value = true
        await authRequest(Endpoint.SaveGoogleAnalyticsAccountDetails, {
            body: {
                domain_id: domainId.value,
                group_id: groupId.value,
                ga_account_id: selectedEntities.value?.account?.id,
                web_property_id: selectedEntities.value?.property?.id,
                profile_id: selectedEntities.value?.profile?.id,
            },
        })

        await refreshAccountProfile()
        savingChanges.value = false
    }

    const linkGaButtonLoading = ref(false)
    const linkGoogleAnalytics = async () => {
        linkGaButtonLoading.value = true
        try {
            const url = await authRequest(Endpoint.GetGaOauthUrl, {
                body: {
                    domain_id: domainId.value,
                    group_id: groupId.value,
                    referral_url: route.path,
                },
            })

            beforeOauthRedirect()

            // Give a bit of time for beforeOAuthRedirect to save stuff to localstorage in report
            await delay(200)

            window.location.href = url
        } catch (err) {
            displayError(AnalyticsTypes.Error.UKNOWN_ERROR)
        } finally {
            linkGaButtonLoading.value = false
        }
    }

    const unlinkGoogleAnalytics = async () => {
        closeViewSelector()
        savingChanges.value = true
        await authRequest(Endpoint.RevokeRefreshToken, {
            body: {
                domain_id: domainId.value,
                group_id: groupId.value,
            },
        })
        await mutateAvailableProfiles()
        await refreshHasConnection()
        savingChanges.value = false
    }

    /*
        Update interom attributes as required
    */
    watch(gaConnections, (newVal, oldVal) => {
        if (newVal && newVal !== oldVal) {
            editUserAttributes({ google_analytics_connections: newVal?.connected_domains })
        }
    })

    return {
        connectionStatusLoading,
        hasConnection,
        linkingDate,
        formattedLinkingDate,
        accountProfile,
        accountProfileError,
        availableProfiles,
        availableProfilesLoading,
        showPreviewModal,
        actuallyShowModal,
        openViewSelector,
        closeViewSelector,
        cancelViewSelection,
        savingChanges,
        linkView,
        slidePreviewButtonShown,
        unlinkGoogleAnalytics,
        linkGoogleAnalytics,
        linkGaButtonLoading,
        gaConnections,
        selectAnalyticsEntity,
        selectedEntities,
        filteredEntities,
        searchAnalyticsEntities,
    }
}
