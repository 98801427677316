<template>
    <div class="rsa-container">
        <header>
            <!-- Mode, Permutation, Reset Draft, Delete Draft -->
            <div class="left">
                <div v-if="isEta" class="draft-mock-input eta">ETA</div>
                <Spacer v-if="isEta" width="0.75rem" />
                <RsaButton :double="true" @left-clicked="isMobileMode = false" @right-clicked="isMobileMode = true">
                    <template v-slot:left>
                        <rsaDesktopIcon :hex="isMobileMode ? '#000' : '#006dff'" />
                    </template>
                    <template v-slot:right>
                        <rsaMobileIcon :hex="isMobileMode ? '#006dff' : '#000'" />
                    </template>
                </RsaButton>
                <Spacer v-if="showRsaPermutationControls" width="0.75rem" />
                <RsaButton
                    v-if="showRsaPermutationControls"
                    :double="true"
                    @left-clicked="prevPermutation()"
                    @right-clicked="nextPermutation()"
                >
                    <template v-slot:left>
                        <rsaLeftArrowIcon />
                    </template>
                    <template v-slot:right>
                        <rsaRightArrowIcon />
                    </template>
                </RsaButton>
                <Spacer width="0.75rem" />
                <slot name="rsa-controls-left"></slot>
            </div>
            <!-- Open, Copy -->
            <div class="right">
                <slot name="rsa-controls-right"></slot>
            </div>
        </header>
        <div :class="[{ mobile: isMobileMode }, { paused: rsa.status === RsaStatus.PAUSED }, 'ad-container']">
            <!-- Ad URL -->
            <div class="ad-url">
                <b>Ad</b>
                <svg
                    style="width: 0.125rem; height: 0.125rem; flex-shrink: 0; outline: none"
                    viewBox="0 0 2 2"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="1" cy="1" r="1" fill="#202124" />
                </svg>
                {{ displayUrl }}
            </div>
            <!-- Headlines -->
            <p :style="{ '-webkit-line-clamp': isMobileMode ? 2 : 1 }" class="headlines">
                <span v-for="(headline, index) in currentPermutation.headlines" class="headline-wrapper">
                    <CopyableString v-if="assetsCopyable" :value="headline.text" />
                    <span v-else>{{ headline.text }}</span>
                    <span v-if="index + 1 != currentPermutation.headlines.length">&nbsp;|&nbsp;</span>
                </span>
            </p>
            <!-- Descriptions -->
            <p :style="{ '-webkit-line-clamp': isMobileMode ? 3 : 2 }" class="descriptions">
                <span v-for="(description, index) in currentPermutation.descriptions">
                    <CopyableString v-if="assetsCopyable" :value="description.text" />
                    <span v-else>{{ description.text }}</span>
                    <span v-if="index + 1 != currentPermutation.headlines.length">&nbsp;</span>
                </span>
            </p>
        </div>
        <Spacer height="2rem" />
        <!-- Status, Statistics, Ad Strength -->
        <div class="rsa-statistics">
            <oTable
                :headers="[
                    { key: 'status', text: 'Status', removePadding: true },
                    { key: 'impressions', text: 'Impr.', removePadding: true, width: below1280 ? 88 : 98 },
                    { key: 'clicks', text: 'Clicks', removePadding: true, width: below1280 ? 82 : 96 },
                    isUsingCpa
                        ? { key: 'conversions', text: 'Conv.', removePadding: true, width: below1280 ? 84 : 98 }
                        : { key: 'conversionsValue', text: 'Value', removePadding: true, width: below1280 ? 84 : 98 },
                    { key: 'ctr', text: 'CTR', removePadding: true, width: below1280 ? 84 : 98 },
                    { key: 'cpi', text: 'CPI', removePadding: true, width: below1280 ? 84 : 98 },
                    { key: 'adStrength', text: 'Ad Strength', removePadding: true, width: 176 },
                ]"
                :items="[
                    {
                        id: 'itemOne',
                        status: rsa.status,
                        impressions: statistics ? statistics[0].value : '0',
                        clicks: statistics ? statistics[1].value : '0',
                        conversions: statistics ? statistics[2].value : '0',
                        ctr: statistics ? statistics[3].value : '0.00%',
                        cpi: statistics ? statistics[4].value : '0.00',
                        conversionsValue: statistics ? statistics[5].value : '0.00',
                        adStrength: '',
                    },
                ]"
                noShadow
                fixedLayout
            >
                <!-- Status -->
                <template #column.status="status">
                    <!-- Draft -->
                    <div v-if="isDraft" class="rsa-table-cell">
                        <div class="status-indicator draft" />
                        <div class="draft-mock-input">
                            Draft
                            <SelectArrowIcon />
                        </div>
                        <slot name="rsa-draft-action"></slot>
                    </div>
                    <!-- Current Draft -->
                    <div v-else-if="isCurrentDraft" class="rsa-table-cell">
                        <div class="status-indicator draft" />
                        <div class="draft-mock-input">
                            Updated
                            <SelectArrowIcon />
                        </div>
                        <slot name="rsa-draft-action"></slot>
                    </div>
                    <!-- Copyable -->
                    <div v-else-if="assetsCopyable">
                        <div v-if="status.value === 1" class="rsa-table-cell">
                            <div class="status-indicator enabled" />
                            <div class="draft-mock-input">
                                Enabled
                                <SelectArrowIcon />
                            </div>
                        </div>
                        <div v-else class="rsa-table-cell">
                            <div class="status-indicator paused" />
                            <div class="draft-mock-input">
                                Paused
                                <SelectArrowIcon />
                            </div>
                        </div>
                    </div>
                    <!-- Content -->
                    <div v-else class="rsa-table-cell">
                        <div v-if="status.value === 1" class="status-indicator enabled" />
                        <div v-if="status.value === 0" class="status-indicator paused" />
                        <slot name="rsa-status"></slot>
                    </div>
                </template>
                <!-- Impressions -->
                <template #column.impressions="impressions">
                    <!-- Draft -->
                    <div v-if="isDraft" class="rsa-table-cell" style="opacity: 0.24">{{ impressions.value }}</div>
                    <!-- Content -->
                    <div v-else class="rsa-table-cell">
                        {{ impressions.value }}
                    </div>
                </template>
                <!-- Clicks -->
                <template #column.clicks="clicks">
                    <!-- Draft -->
                    <div v-if="isDraft" class="rsa-table-cell" style="opacity: 0.24">{{ clicks.value }}</div>
                    <!-- Content -->
                    <div v-else class="rsa-table-cell">
                        {{ clicks.value }}
                    </div>
                </template>
                <!-- Conversions -->
                <template #column.conversions="conversions">
                    <!-- Draft -->
                    <div v-if="isDraft" class="rsa-table-cell" style="opacity: 0.24">{{ conversions.value }}</div>
                    <!-- Content -->
                    <div v-else class="rsa-table-cell">
                        {{ conversions.value }}
                    </div>
                </template>
                <!-- CTR -->
                <template #column.ctr="ctr">
                    <!-- Draft -->
                    <div v-if="isDraft" class="rsa-table-cell" style="opacity: 0.24">{{ ctr.value }}</div>
                    <!-- Content -->
                    <div v-else class="rsa-table-cell">
                        {{ ctr.value }}
                    </div>
                </template>
                <!-- CPI -->
                <template #column.cpi="cpi">
                    <!-- Draft -->
                    <div v-if="isDraft" class="rsa-table-cell" style="opacity: 0.24">{{ cpi.value }}</div>
                    <!-- Content -->
                    <div v-else class="rsa-table-cell">
                        {{ cpi.value }}
                    </div>
                </template>
                <!-- Ad Strength -->
                <template #column.adStrength="adStrength">
                    <!-- Draft -->
                    <div v-if="isDraft || isEta" class="rsa-table-cell">
                        <div class="draft-mock-ad-strength">
                            <div class="draft-mock-ad-strength-icon" />
                            Unavailable
                        </div>
                    </div>
                    <!-- Content -->
                    <div v-else class="rsa-table-cell">
                        <slot name="rsa-ad-strength"></slot>
                    </div>
                </template>
            </oTable>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, toRef } from 'vue'
import { RsaWriter, Targets } from '@opteo/types'
import { CurrencyCode } from '@opteo/types/currency'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { useRsaDisplayUrl, useRsaPermutations, useRsaStatistics } from './useRsa'

import {
    Text,
    Spacer,
    ColorTag,
    oButton,
    oTable,
    CopyableString,
    rsaDesktopIcon,
    rsaMobileIcon,
    rsaLeftArrowIcon,
    rsaRightArrowIcon,
    SelectArrowIcon,
} from '@opteo/components-next'
import RsaButton from '@/components/rsaWriter/RsaButton.vue'

export default defineComponent({
    name: 'Rsa',
    components: {
        Text,
        Spacer,
        ColorTag,
        oButton,
        RsaButton,
        CopyableString,
        rsaDesktopIcon,
        rsaMobileIcon,
        rsaLeftArrowIcon,
        rsaRightArrowIcon,
        oTable,
        SelectArrowIcon,
    },
    props: {
        rsa: {
            type: Object as PropType<RsaWriter.RsaAd>,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        isDraft: {
            type: Boolean,
            required: false,
            default: false,
        },
        isCurrentDraft: {
            type: Boolean,
            required: false,
            default: false,
        },
        isEta: {
            type: Boolean,
            required: false,
            default: false,
        },
        assetsCopyable: {
            type: Boolean,
            required: false,
            default: false,
        },
        showRsaPermutationControls: {
            type: Boolean,
            required: false,
            default: true,
        },
        performanceMode: {
            type: Number as PropType<Targets.PerformanceMode>,
            required: false,
            default: Targets.PerformanceMode.CPA,
        },
        currencyCode: {
            type: String as PropType<CurrencyCode>,
            required: false,
        },
    },
    setup(props) {
        const { below1280 } = useMediaQuery()

        const isMobileMode = ref(false)

        const displayUrl = useRsaDisplayUrl(toRef(props, 'rsa'))

        const { currentPermutation, nextPermutation, prevPermutation } = useRsaPermutations(toRef(props, 'rsa'))

        const { statistics } = useRsaStatistics(toRef(props, 'rsa'), props.currencyCode)

        const isUsingCpa = computed(
            () => !props.performanceMode || props.performanceMode === Targets.PerformanceMode.CPA,
        )

        return {
            below1280,
            isMobileMode,
            displayUrl,
            currentPermutation,
            prevPermutation,
            nextPermutation,
            RsaStatus: RsaWriter.RsaStatus,
            statistics,
            isUsingCpa,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.rsa-container {
    @include container;
    @include br-24;
    // max-width: 55rem;
    margin: 0 auto;
    @include w-100;
    overflow: hidden;
    font-feature-settings:
        'ss01' off,
        'ss02' off,
        'ss04' off,
        'ss05' off,
        'ss08' off;
}
.rsa-container:last-child {
    margin: 0 auto;
}
.rsa-container header {
    @include flex;
    @include items-center;
    @include justify-between;
    @include pa-32;
    // height: 1.75rem;
}
.rsa-container header .left {
    @include flex;
    @include items-center;
}
.rsa-container header .header-slot {
    @include flex;
    @include items-center;
}

// RSA Container
.rsa-container .ad-container {
    @include block;
    @include container;
    @include br-20;
    @include pa-40;
    max-width: 42.5rem;
    width: 100%;
    margin: 0 auto;
}
.rsa-container .ad-container .ad-url {
    @include flex;
    @include items-center;
    font-family: Arial, sans-serif;
    color: #202124;
    font-size: 0.875rem;
    letter-spacing: 0;
    white-space: nowrap;
    @include no-select;
}
.rsa-container .ad-container .ad-url b {
    letter-spacing: -0.4px;
}
.rsa-container .ad-container .ad-url svg {
    margin: 0 0.3125rem;
}
.rsa-container .ad-container .headlines {
    @include block;
    font-family: 'Google Sans Text', sans-serif;
    font-size: 1.25rem;
    letter-spacing: -0.005rem;
    line-height: 1.625rem;
    color: #1a0dab;
    margin-top: 0.5rem;
    margin-bottom: 0.375rem;
    -webkit-font-smoothing: subpixel-antialiased;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include no-select;
}
.rsa-container .ad-container .headlines .headline-wrapper {
    @include inline;
}
.rsa-container .ad-container .descriptions {
    @include block;
    font-family: Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: 0;
    color: #4d5156;
    max-width: 600px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include no-select;
}
.rsa-container .ad-container .statistics {
    @include inline-flex;
    @include items-center;
}
.rsa-container .ad-container .statistic {
    margin-right: 0.375rem;
}

// RSA Statistics
.rsa-container .rsa-statistics {
    border-top: 1px solid $opteo-light-gray;
}
.rsa-container .rsa-statistics .rsa-table-cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    @include flex;
    @include items-center;
    gap: 0.75rem;
}

// RSA Status
.rsa-container .status-indicator {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 999px;
    flex-shrink: 0;
}
.rsa-container .status-indicator.draft {
    @include bg-opteo-blue;
}
.rsa-container .status-indicator.enabled {
    @include bg-opteo-green;
}
.rsa-container .status-indicator.paused {
    @include bg-opteo-amber;
}

.rsa-container .draft-mock-input {
    height: 2.75rem;
    @include container;
    @include br-8;
    @include flex;
    @include items-center;
    padding: 0 0.875rem;
    gap: 1.25rem;
    color: rgba(0, 0, 0, 0.24);
    cursor: default;
    user-select: none;
}
.rsa-container .draft-mock-input.eta {
    font-size: 0.75rem;
    letter-spacing: -0.0125rem;
    font-weight: 500;
    color: $opteo-black;
}

.rsa-container .draft-mock-ad-strength {
    height: 2.75rem;
    @include container;
    @include br-8;
    @include flex;
    @include items-center;
    padding: 0 0.875rem;
    gap: 0.5rem;
    color: rgba(0, 0, 0, 0.24);
    cursor: default;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8125rem;
    letter-spacing: -0.0125rem;
    font-weight: 500;
}
.rsa-container .draft-mock-ad-strength-icon {
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 999px;
    border: 2px solid rgba(0, 0, 0, 0.16);
    flex-shrink: 0;
}

// RSA Container (Mobile)
.rsa-container .ad-container.mobile {
    max-width: 27.5rem;
}
.rsa-container .ad-container.mobile .headlines {
    margin-top: 0.6875rem;
    margin-bottom: 0.5625rem;
}
.rsa-container .ad-container.ad-container.mobile .statistics {
    @include container;
    @include pa-16;
    max-width: 100%;
}

// RSA Container (Paused)
// .rsa-container .ad-container.paused .headlines,
// .rsa-container .ad-container.paused .descriptions,
// .rsa-container .ad-container.paused .ad-url {
//     color: #010105;
//     opacity: 0.24;
//     transition: color 0s ease, opacity 0.16s ease;
// }
// .rsa-container .ad-container.paused .ad-url svg circle {
//     fill: #010105;
// }

// .rsa-container:hover .ad-container.paused .headlines {
//     color: #1a0dab;
//     opacity: 1;
// }
// .rsa-container:hover .ad-container.paused .descriptions {
//     color: #4d5156;
//     opacity: 1;
// }
// .rsa-container:hover .ad-container.paused .ad-url {
//     color: #202124;
//     opacity: 1;
// }
// .rsa-container:hover .ad-container.paused .ad-url svg circle {
//     fill: #202124;
// }

.rsa-container .rsa-controls,
.rsa-container .rsa-controls .left,
.rsa-container .rsa-controls .right {
    @include flex;
    @include items-center;
}
.rsa-container .rsa-controls {
    @include justify-between;
}

.headlines.clamp,
.descriptions.clamp {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
</style>
