<template>
    <ScorecardSection
        :score="score"
        :invalid="invalid"
        :isLoading="isLoading"
        :domainName="domainName"
        :section-type="sectionType"
        :section-name="sectionName"
        :mode="mode"
        @score-history-button-clicked="emit('score-history-button-clicked')"
    >
        <template #content>
            <div v-for="copy in getSectionCopy" :key="copy">
                <Text as="p" size="f-7">{{ copy }}</Text>
                <Spacer height="1rem" />
            </div>
            <Spacer height="1rem" />

            <!-- Channel Distribution -->
            <div class="flex items-center justify-between">
                <Text as="h6" weight="600">Channel Distribution</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="openCampaignBreakdown('channel_distribution')"
                    color="white"
                    size="small"
                    :loading="showChannelDistributionBreakdown && breakdownLoading"
                >
                    Campaign Breakdown
                </oButton>
            </div>
            <Spacer height="1.5rem" />

            <Text as="p" size="f-7">
                Below is an overview to help you understand how Google distributes your spend across
                different advertising channels.
            </Text>

            <Spacer height="1.5rem" />

            <ChannelDistributionData
                :donut-chart-items="donutChartItems"
                :table-headers="channelDistributionTableHeaders"
                :table-items="channelDistributionTableItems"
                :is-pdf="isPdf"
                :domain-currency="domainCurrency"
                :no-spend="zeroPmaxCampaignSpend ?? false"
            />

            <Spacer height="2.5rem" />

            <!-- Input Coverage -->
            <div class="flex items-center justify-between">
                <Text as="h6" weight="600">Input Coverage</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="openCampaignBreakdown('input_coverage')"
                    color="white"
                    size="small"
                    :loading="showInputCoverageBreakdown && breakdownLoading"
                >
                    Campaign Breakdown
                </oButton>
            </div>
            <Spacer height="1.5rem" />

            <Text as="p" size="f-7">
                The table below includes any Performance Max campaigns that could benefit from
                additional assets. The campaigns in this list are impression weighted and prioritise
                high traffic campaigns.
            </Text>
            <Spacer height="1.5rem" />

            <oTable
                :headers="inputCoverageTableHeaders"
                :items="inputCoverageTableItems"
                :fixed-layout="true"
                :border-radius="20"
                :class="{ 'pdf-shadow-fix': isPdf }"
                responsive-mode="columns"
                responsive-breakpoint="767px"
                responsive-col-size="40% 1fr"
            >
                <template #header.campaign>
                    <div class="header-cell">Campaign</div>
                </template>
                <template #column.campaign="cellData">
                    <div class="column-overlay">
                        <Tooltip
                            :content="cellData.value.length >= 20 ? cellData.value : ''"
                            :offset="[0, 8]"
                            :max-width="700"
                            placement="top-start"
                            style="width: 100%"
                        >
                            <EntityPill
                                type="campaign"
                                :content="cellData.value"
                                :tooltip="false"
                            />
                        </Tooltip>
                    </div>
                </template>
                <template #column.assetType="cellData">
                    <div class="column-overlay-no-padding">
                        {{ cellData.value }}
                    </div>
                </template>
                <template #column.status="cellData">
                    <Text
                        as="p"
                        size="f-8"
                        :color="
                            inputCoverageStatusColor(cellData.row.count, cellData.row.recommended)
                        "
                        weight="500"
                    >
                        {{ inputCoverageStatus(cellData.row.count, cellData.row.recommended) }}
                    </Text>
                </template>
            </oTable>
            <Spacer height="2.5rem" />

            <!-- Audience Signals -->
            <div class="flex items-center justify-between">
                <Text as="h6" weight="600">Audience Signals</Text>
                <oButton
                    v-if="mode !== 'pdf' && aboveiPadLandscape"
                    @clicked="openCampaignBreakdown('audience_signals')"
                    color="white"
                    size="small"
                    :loading="showAudienceSignalsBreakdown && breakdownLoading"
                >
                    Campaign Breakdown
                </oButton>
            </div>
            <Spacer height="1.5rem" />

            <oTable
                :headers="audienceSignalsTableHeaders"
                :items="audienceSignalsTableItems"
                :border-radius="20"
                :class="{ 'pdf-shadow-fix': isPdf }"
                responsive-mode="columns"
                responsive-breakpoint="767px"
                responsive-col-size="40% 1fr"
            >
                <template #column.usedIn="cellData">
                    <Text as="p" size="f-8"
                        >{{ cellData.value }}
                        <Text as="span" color="gray">
                            of {{ totalNumberOfAssetGroups }} Asset Groups</Text
                        ></Text
                    >
                </template>
            </oTable>
        </template>
    </ScorecardSection>

    <!-- Channel Distribution Breakdown Panel -->
    <BreakdownPanel
        @breakdown-requested="emit('breakdown-requested')"
        :showPanel="showChannelDistributionBreakdown"
        :closePanel="closeCampaignBreakdown"
        title="Channel Distribution"
        :width="1024"
        :loading="breakdownLoading"
    >
        <template #title>
            <ColorTag color="blue" title="Campaign Breakdown" />
        </template>
        <template #content>
            <div
                v-for="(campaign, index) in uniqChannelDistributionBreakdownTableItems"
                :class="[
                    'campaign-breakdown-container',
                    {
                        'mb-36':
                            uniqChannelDistributionBreakdownTableItems?.length &&
                            index !== uniqChannelDistributionBreakdownTableItems?.length - 1,
                    },
                ]"
            >
                <BreakdownTableHeader
                    :entity-label="campaign.campaign"
                    :currency="domainCurrency"
                    :cost="totalCostPerCampaign?.[campaign.resource_name] ?? 0"
                />
                <Spacer height="2.25rem" />
                <oTable
                    :headers="channelDistributionBreakdownTableHeaders"
                    :items="
                        channelDistributionBreakdownTableItemsByCampaign[campaign.resource_name]
                    "
                    :fixed-layout="true"
                    :border-radius="20"
                    :class="{ 'pdf-shadow-fix': isPdf }"
                >
                    <template #column.cost="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value" />
                    </template>
                    <template #column.conversions="cellData">
                        <NumberVue :value="cellData.value" />
                    </template>
                    <template #column.cpa="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value" />
                    </template>
                    <template #column.conversionsValue="cellData">
                        <Money :currency="domainCurrency" :value="cellData.value" />
                    </template>
                    <template #column.roas="cellData">
                        <Roas :value="cellData.value" />
                    </template>
                </oTable>
            </div>
        </template>
        <template #footer>
            <oButton @clicked="formatAndDownloadCsv('channelDistribution')" size="extra-large">
                Download CSV
            </oButton>
        </template>
    </BreakdownPanel>

    <!-- Input Coverage Breakdown Panel -->
    <BreakdownPanel
        @breakdown-requested="emit('breakdown-requested')"
        :showPanel="showInputCoverageBreakdown"
        :closePanel="closeCampaignBreakdown"
        title="Input Coverage"
        :width="1024"
        :loading="breakdownLoading"
    >
        <template #title>
            <ColorTag color="blue" title="Campaign Breakdown" />
        </template>
        <template #content>
            <div
                v-for="(campaign, index) in uniqInputCoverageBreakdownTableItems"
                :class="[
                    'campaign-breakdown-container',
                    {
                        'mb-36':
                            uniqInputCoverageBreakdownTableItems.length &&
                            index !== uniqInputCoverageBreakdownTableItems?.length - 1,
                    },
                ]"
            >
                <BreakdownTableHeader
                    :entity-label="campaign.campaign"
                    :currency="domainCurrency"
                    :cost="totalCostPerCampaign?.[campaign.resource_name] ?? 0"
                />
                <Spacer height="2.25rem" />

                <oTable
                    :headers="inputCoverageBreakdownTableHeaders"
                    :items="inputCoverageBreakdownTableItemsByCampaign[campaign.resource_name]"
                    :fixed-layout="true"
                    :border-radius="20"
                    :per-page="4"
                    :persist-height="true"
                    :class="{ 'pdf-shadow-fix': isPdf }"
                >
                    <template #header.assetGroup>
                        <div class="header-cell">Asset Group</div>
                    </template>
                    <template
                        #column.assetGroup="{
                            row: { assetGroup, assetType },
                        }: Record<'row', Scorecard.InputCoverageCampaignBreakdown>"
                    >
                        <div class="column-overlay">
                            <Tooltip
                                v-if="!assetGroup"
                                content="Assets of this type are yet to be defined for this campaign"
                                :offset="[0, 8]"
                                :max-width="700"
                                placement="top-start"
                            >
                                <EntityPill
                                    type="asset-group"
                                    :disabled="true"
                                    content="Undefined"
                                    :tooltip="false"
                                />
                            </Tooltip>
                            <Tooltip
                                v-else
                                :content="assetGroup.length >= 44 ? assetGroup : ''"
                                :offset="[0, 8]"
                                :max-width="700"
                                placement="top-start"
                            >
                                <EntityPill
                                    type="asset-group"
                                    :content="assetGroup"
                                    :tooltip="false"
                                />
                            </Tooltip>
                        </div>
                    </template>
                    <template #column.status="cellData">
                        <Text
                            as="p"
                            size="f-8"
                            :color="
                                inputCoverageStatusColor(
                                    cellData.row.count,
                                    cellData.row.recommended
                                )
                            "
                            weight="500"
                        >
                            {{ inputCoverageStatus(cellData.row.count, cellData.row.recommended) }}
                        </Text>
                    </template>
                </oTable>
            </div>
        </template>
        <template #footer>
            <oButton @clicked="formatAndDownloadCsv('inputCoverage')" size="extra-large">
                Download CSV
            </oButton>
        </template>
    </BreakdownPanel>

    <!-- Audience Signals Breakdown Panel -->
    <BreakdownPanel
        @breakdown-requested="emit('breakdown-requested')"
        :showPanel="showAudienceSignalsBreakdown"
        :closePanel="closeCampaignBreakdown"
        title="Audience Signals"
        :width="1024"
        :loading="breakdownLoading"
    >
        <template #title>
            <ColorTag color="blue" title="Campaign Breakdown" />
        </template>
        <template #content>
            <div
                v-for="(campaign, index) in uniqAudienceSignalsBreakdownTableItems"
                :class="[
                    'campaign-breakdown-container',
                    {
                        'mb-36':
                            uniqAudienceSignalsBreakdownTableItems?.length &&
                            index !== uniqAudienceSignalsBreakdownTableItems?.length - 1,
                    },
                ]"
            >
                <BreakdownTableHeader
                    :entity-label="campaign.campaign"
                    :currency="domainCurrency"
                    :cost="totalCostPerCampaign?.[campaign.resource_name] ?? 0"
                />
                <Spacer height="2.25rem" />
                <oTable
                    :headers="audienceSignalsBreakdownTableHeaders"
                    :items="audienceSignalsBreakdownTableItemsByCampaign[campaign.resource_name]"
                    :fixed-layout="true"
                    :border-radius="20"
                    :per-page="4"
                    :persist-height="true"
                    :class="{ 'pdf-shadow-fix': isPdf }"
                >
                    <template #header.assetGroup>
                        <div class="header-cell">Asset Group</div>
                    </template>
                    <template #header.audienceSignal>
                        <div class="header-cell">Audience Signal</div>
                    </template>
                    <template
                        #column.assetGroup="{
                            row: { assetGroup, audienceSignal },
                        }: Record<'row', Scorecard.AudienceSignalBreakdown>"
                    >
                        <div class="column-overlay">
                            <Tooltip
                                v-if="!assetGroup"
                                content="Audience signals of this type are yet to be defined for this campaign"
                                :offset="[0, 8]"
                                :max-width="700"
                                placement="top-start"
                            >
                                <EntityPill
                                    type="asset-group"
                                    :disabled="true"
                                    content="Undefined"
                                    :tooltip="false"
                                />
                            </Tooltip>
                            <Tooltip
                                v-else
                                :content="assetGroup.length >= 48 ? assetGroup : ''"
                                :offset="[0, 8]"
                                :max-width="700"
                                placement="top-start"
                            >
                                <EntityPill
                                    type="asset-group"
                                    :content="assetGroup"
                                    :tooltip="false"
                                />
                            </Tooltip>
                        </div>
                    </template>
                    <template #column.audienceSignal="cellData">
                        <div class="pl-24">
                            {{ cellData.value }}
                        </div>
                    </template>
                </oTable>
            </div>
        </template>
        <template #footer>
            <oButton @clicked="formatAndDownloadCsv('audienceSignals')" size="extra-large">
                Download CSV
            </oButton>
        </template>
    </BreakdownPanel>
</template>

<script lang="ts">
import clone from 'lodash-es/clone'
import groupBy from 'lodash-es/groupBy'
import uniqBy from 'lodash-es/uniqBy'
import sortBy from 'lodash-es/sortBy'

import { Scorecard } from '@opteo/types'
import { defineComponent, computed, PropType, ref, Ref } from 'vue'

import useMediaQuery from '@/composition/global/useMediaQuery'

import Skeleton from '@/components/util/Skeleton.vue'

import SectionHidden from './SectionHidden.vue'
import { formatDonutChartData } from './utils'
import { TextOptions, getSectionCopy } from './utils'
import { sortCollectionBy, downloadCsv } from '@/lib/globalUtils'
import ScorecardDonut from './ScorecardDonut.vue'
import ScorecardSection from './ScorecardSection.vue'
import ChannelDistributionData from './ChannelDistributionData.vue'
import BreakdownTableHeader from './BreakdownTableHeader.vue'
import BreakdownPanel from './BreakdownPanel.vue'

import {
    Text,
    Spacer,
    oTable,
    Money,
    Percent,
    Number as NumberVue,
    Roas,
    oButton,
    Tooltip,
    ColorTag,
    DonutChart,
    EntityPill,
} from '@opteo/components-next'

export default defineComponent({
    name: 'PerformanceMax',
    props: {
        score: {
            type: Number,
        },
        isLoading: {
            type: Boolean,
        },
        invalid: {
            type: Boolean,
        },
        domainCurrency: {
            type: String,
            required: true,
        },
        domainName: {
            type: String,
        },
        isUsingCpa: {
            type: Boolean,
            default: true,
        },
        details: {
            type: Object as PropType<Scorecard.PerformanceMaxScoreDetails>,
        },
        breakdown: {
            type: Object as PropType<Scorecard.PerformanceMaxScoreBreakdown>,
        },
        mode: {
            type: String as PropType<'live' | 'pdf' | 'app'>,
            required: true,
        },
        breakdownLoading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Text,
        Spacer,
        DonutChart,
        oTable,
        Money,
        Percent,
        Roas,
        NumberVue,
        SectionHidden,
        ScorecardDonut,
        ScorecardSection,
        Skeleton,
        oButton,
        BreakdownPanel,
        Tooltip,
        ColorTag,
        EntityPill,
        ChannelDistributionData,
        BreakdownTableHeader,
    },
    emits: ['score-history-button-clicked', 'breakdown-requested'],
    setup(props, { emit }) {
        const isPdf = props.mode === 'pdf'
        const { aboveiPadLandscape } = useMediaQuery()
        const sectionName = Scorecard.SectionNames.performanceMax

        // Breakdown Panel
        type BreakdownType = 'channel_distribution' | 'input_coverage' | 'audience_signals'
        const breakdownInFocus: Ref<BreakdownType | undefined> = ref()

        const showChannelDistributionBreakdown = computed(
            () => breakdownInFocus.value === 'channel_distribution'
        )
        const showInputCoverageBreakdown = computed(
            () => breakdownInFocus.value === 'input_coverage'
        )
        const showAudienceSignalsBreakdown = computed(
            () => breakdownInFocus.value === 'audience_signals'
        )

        const openCampaignBreakdown = (breakdownType: BreakdownType) => {
            breakdownInFocus.value = breakdownType
        }

        const closeCampaignBreakdown = () => {
            breakdownInFocus.value = undefined
        }
        function formatAndDownloadCsv(dataSet: string) {
            let items
            let options
            if (dataSet === 'channelDistribution') {
                items = channelDistributionBreakdownTableItems.value?.map(item => {
                    const clonedItem = clone(item) as any
                    delete clonedItem.resource_name
                    return clonedItem
                })
                options = {
                    initialColumnHeaders: [
                        'Campaign',
                        'Channel',
                        'Cost',
                        'Conversions',
                        'Cpa',
                        'Conversions Value',
                        'Roas',
                    ],
                }
            } else if (dataSet === 'inputCoverage') {
                items = inputCoverageBreakdownTableItems.value?.map(item => {
                    const clonedItem: any = clone(item)
                    const { count, recommended } = clonedItem
                    const status = inputCoverageStatus(count, recommended)
                    const copy = Object.assign(clonedItem, { status })
                    delete copy.resource_name
                    delete copy.recommended

                    return copy
                })
                options = {
                    initialColumnHeaders: [
                        'Campaign',
                        'Asset Group',
                        'Asset Type',
                        'Count',
                        'Status',
                    ],
                }
            } else if (dataSet === 'audienceSignals') {
                items = audienceSignalsBreakdownTableItems.value?.map(item => {
                    const clonedItem: any = clone(item)
                    delete clonedItem.resource_name
                    return clonedItem
                })
                options = {
                    initialColumnHeaders: ['Campaign', 'Asset Group', 'Audience Signal', 'Count'],
                }
            } else {
                throw new Error('dataset type missing')
            }

            downloadCsv({
                dataSet,
                items: items!,
                options,
            })
        }

        const channelDistributionTableItems = computed(() => props.details?.channel_distribution)

        // Channel Distribution
        const donutChartItems = computed(() => {
            const initialValue = 0
            const totalCost =
                channelDistributionTableItems?.value?.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.cost
                }, initialValue) ?? 0

            const shopping = channelDistributionTableItems?.value?.find(
                i => i.channel === 'Shopping'
            )
            const video = channelDistributionTableItems?.value?.find(i => i.channel === 'Video')
            const display = channelDistributionTableItems?.value?.find(i => i.channel === 'Display')
            const other = channelDistributionTableItems?.value?.find(i => i.channel === 'Other')

            const shoppingPercent = shopping?.cost && totalCost ? shopping?.cost / totalCost : 0
            const videoPercent = video?.cost && totalCost ? video?.cost / totalCost : 0
            const displayPercent = display?.cost && totalCost ? display?.cost / totalCost : 0
            const otherPercent = other?.cost && totalCost ? other?.cost / totalCost : 0

            return [
                { y: shoppingPercent, label: 'Shopping' },
                { y: videoPercent, label: 'Video' },
                { y: displayPercent, label: 'Display' },
                { y: otherPercent, label: 'Other' },
            ]
        })
        const channelDistributionTableHeaders = computed(() => [
            { key: 'channel', text: 'Channel' },
            { key: 'cost', text: 'Cost', width: 88 },
            { key: 'conversions', text: 'Conv.', width: 80 },
            { key: 'conversionsValue', text: 'Value', width: 84 },
            props.isUsingCpa
                ? { key: 'cpa', text: 'CPA', width: 100 }
                : { key: 'roas', text: 'ROAS', width: 100 },
        ])

        const channelDistributionBreakdownTableHeaders = [
            { key: 'channel', text: 'Channel', sortable: true },
            { key: 'cost', text: 'Cost', width: 116, sortable: true },
            { key: 'conversions', text: 'Conv.', width: 100, sortable: true },
            { key: 'cpa', text: 'CPA', width: 116, sortable: true },
            { key: 'conversionsValue', text: 'Value', width: 116, sortable: true },
            { key: 'roas', text: 'ROAS', width: 134, sortable: true },
        ]

        const totalCostPerCampaign = computed(() => props.breakdown?.totalCostPerCampaign)

        const channelDistributionBreakdownTableItems = computed(
            () => props.breakdown?.channel_distribution
        )

        const uniqChannelDistributionBreakdownTableItems = computed(() =>
            sortBy(
                uniqBy(props.breakdown?.channel_distribution, ch => ch.resource_name),
                c => {
                    return -totalCostPerCampaign?.value?.[c.resource_name] ?? 0
                }
            )
        )

        const channelDistributionBreakdownTableItemsByCampaign = computed(() =>
            groupBy(props.breakdown?.channel_distribution, c => c.resource_name)
        )

        const zeroPmaxCampaignSpend = computed(() =>
            channelDistributionBreakdownTableItems?.value?.every(channel => channel.cost === 0)
        )

        // Input Coverage
        const inputCoverageTableHeaders = [
            { key: 'campaign', text: 'Campaign', width: 204, noPadding: true },
            { key: 'assetType', text: 'Asset Type', width: 126, noPadding: true },
            { key: 'status', text: 'Status', width: 98, noPadding: true },
        ]
        const inputCoverageTableItems = computed(() => props.details?.input_coverage)

        const inputCoverageBreakdownTableHeaders = [
            { key: 'assetGroup', text: 'Asset Group', sortable: true, noPadding: true },
            { key: 'assetType', text: 'Asset Type', width: 192, sortable: true },
            { key: 'count', text: 'Count', width: 90, sortable: true, noPadding: true },
            { key: 'status', text: 'Status', width: 108, sortable: true, noPadding: true },
        ]

        const inputCoverageBreakdownTableItems = computed(() => {
            const { breakdown } = props

            if (!breakdown?.input_coverage) return []

            return breakdown.input_coverage.map(item => ({
                ...item,
                // Recommended additions, used for sorting by status on the table
                status: item.recommended - item.count,
            }))
        })
        const uniqInputCoverageBreakdownTableItems = computed(() =>
            sortBy(
                uniqBy(
                    inputCoverageBreakdownTableItems.value,
                    ({ resource_name }) => resource_name
                ),
                c => {
                    return -totalCostPerCampaign?.value?.[c.resource_name] ?? 0
                }
            )
        )

        const inputCoverageBreakdownTableItemsByCampaign = computed(() => {
            const inputCoverageItemsByAssetType = sortCollectionBy({
                collectionToSort: inputCoverageBreakdownTableItems.value,
                sortingValue: item => item.campaign,
                arrayToSortBy: Scorecard.FormattedAssetTypes,
            })

            return groupBy(inputCoverageItemsByAssetType, ({ resource_name }) => resource_name)
        })

        function inputCoverageStatus(count: number, recommended: number) {
            if (count >= recommended) {
                return 'Complete'
            } else if (count < recommended) {
                return `Add ${recommended - count} more`
            }
            return 'Error'
        }
        function inputCoverageStatusColor(count: number, recommended: number) {
            // If number of assets meets the recommended number of assets return 'green'
            // If number of assets is < recommended number of assets and recommended = 1 return 'amber'
            // If number of assets is >= 50% recommended number of assets and < recommended return 'amber'
            // If number of assets is < 50% recommended number of assets return 'red'
            if (count >= recommended) {
                return 'green'
            } else if (count < recommended && recommended === 1) {
                return 'amber'
            } else if (count >= Math.round(recommended / 2)) {
                return 'amber'
            }
            return 'red'
        }

        // Audience Signals
        const audienceSignalsTableHeaders = [
            { key: 'audienceSignal', text: 'Type' },
            { key: 'usedIn', text: 'Used In' },
        ]
        const audienceSignalsTableItems = computed(() => props.details?.audience_signal)

        const audienceSignalsBreakdownTableHeaders = [
            { key: 'assetGroup', text: 'Asset Group', sortable: true, noPadding: true },
            {
                key: 'audienceSignal',
                text: 'Audience Signal',
                width: 226,
                sortable: true,
                noPadding: true,
            },
            { key: 'count', text: 'Count', width: 104, sortable: true, noPadding: true },
        ]
        const audienceSignalsBreakdownTableItems = computed(() => props.breakdown?.audience_signal)

        const uniqAudienceSignalsBreakdownTableItems = computed(() =>
            sortBy(
                uniqBy(props.breakdown?.audience_signal, a => a.resource_name),
                c => {
                    return -totalCostPerCampaign?.value?.[c.resource_name] ?? 0
                }
            )
        )

        const audienceSignalsBreakdownTableItemsByCampaign = computed(() =>
            groupBy(props.breakdown?.audience_signal, c => c.resource_name)
        )

        const totalNumberOfAssetGroups = computed(() => props?.details?.totalNumberOfAssetGroups)

        const textOptions = computed<TextOptions>(() => {
            return {
                0: [
                    `To get the most out of your Performance Max campaigns, make sure to provide Google with enough assets and audience signals to test for profitable combinations. Aim to use a variety of asset types across your campaigns, the more assets you provide, the more combinations Google has available to test.`,
                ],
            }
        })

        const _getSectionCopy = computed((): string[] => {
            return getSectionCopy(textOptions.value, props.score ?? 0) ?? []
        })

        return {
            sectionType: Scorecard.SectionTypes.performanceMax,
            textOptions,
            formatDonutChartData,
            getSectionCopy: _getSectionCopy,
            isPdf,
            sectionName,
            aboveiPadLandscape,
            // Breakdown Panel
            showChannelDistributionBreakdown,
            showInputCoverageBreakdown,
            showAudienceSignalsBreakdown,
            openCampaignBreakdown,
            closeCampaignBreakdown,
            formatAndDownloadCsv,
            totalCostPerCampaign,
            // Channel Distribution
            donutChartItems,
            channelDistributionTableHeaders,
            channelDistributionTableItems,
            channelDistributionBreakdownTableHeaders,
            // channelDistributionBreakdownTableItems,
            uniqChannelDistributionBreakdownTableItems,
            channelDistributionBreakdownTableItemsByCampaign,

            zeroPmaxCampaignSpend,
            // Input Coverage
            inputCoverageTableHeaders,
            inputCoverageTableItems,
            inputCoverageBreakdownTableHeaders,
            // inputCoverageBreakdownTableItems,
            uniqInputCoverageBreakdownTableItems,
            inputCoverageBreakdownTableItemsByCampaign,
            inputCoverageStatus,
            inputCoverageStatusColor,
            // Audience Signals
            audienceSignalsTableHeaders,
            audienceSignalsTableItems,
            audienceSignalsBreakdownTableHeaders,
            // audienceSignalsBreakdownTableItems,
            uniqAudienceSignalsBreakdownTableItems,
            audienceSignalsBreakdownTableItemsByCampaign,
            totalNumberOfAssetGroups,
            emit,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.campaign-breakdown-container {
    @include container;
    @include br-28;
    @include pa-36;
}

.column-overlay-no-padding {
    max-width: 100%;
    overflow: hidden;
    @include flex;
    @include items-center;
}
.column-overlay-no-padding::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}
.column-overlay {
    max-width: 100%;
    height: 4.5rem;
    overflow: hidden;
    @include pl-24;
    @include flex;
    @include items-center;
}
.column-overlay::after {
    content: '';
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 1.5rem, rgba(255, 255, 255, 0) 100%);
    @include absolute;
    top: 0;
    right: 0;
    width: 4rem;
    bottom: 0;
}

.header-cell {
    @include pl-24;
}

:deep(.improvement-note) {
    @include br-20;
}

@media screen and (max-width: $mq-767-max) {
    .header-cell {
        padding-left: unset;
    }
    .column-overlay,
    .column-overlay-no-padding {
        height: unset;
        padding-left: unset;
        overflow: unset;
        @include w-100;
        min-width: unset;
    }
    .column-overlay::after,
    .column-overlay-no-padding::after {
        display: none;
    }
}
</style>
