<template>
    <div class="improvement-row-container" tabindex="0">
        <div
            class="improvement-row no-select"
            :class="{ interactive: isInteractive, toolprovement: isToolProvement(improvement) }"
            style="height: 72px; overflow: hidden"
            ref="improvementRow"
        >
            <div class="flex items-center w-100 h-100">
                <!-- HEADER LABEL -->
                <div
                    class="flex items-center h-100 w-100"
                    @click="openImprovement"
                    v-if="!isSkeletonMode"
                >
                    <!-- LABEL -->
                    <h4 class="improvement-name">
                        {{
                            isToolProvement(improvement)
                                ? improvement.static_title
                                : improvement.title
                        }}
                    </h4>
                    <!-- ACCOUNT LOCATION -->
                    <div class="flex items-center" style="gap: 0.625rem">
                        <ImprovementLocation
                            v-for="location in improvement.location"
                            :key="location.entity"
                            :entity="location.entity"
                            :label="location.label || ''"
                        />
                    </div>
                </div>

                <div v-else class="flex items-center h-100 w-100">
                    <svg
                        class="improvement-row-icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 2C6.477153 2 2 6.4771525 2 12s4.477153 10 10 10 10-4.4771525 10-10S17.522847 2 12 2zm0-2c6.627417 0 12 5.372583 12 12s-5.372583 12-12 12S0 18.627417 0 12 5.372583 0 12 0z"
                            fill-rule="nonzero"
                            fill="#01010514"
                        ></path>
                    </svg>
                    <h4 class="improvement-name">
                        <Skeleton :width="Math.random() * 120 + 100" :height="18" />
                    </h4>
                    <div v-for="skid in 2" :key="skid" class="flex items-center mr3">
                        <Skeleton :width="80" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Icon -->
        <div v-if="!isSkeletonMode" class="improvement-row-icon">
            <slot name="icon" v-if="!isSkeletonMode" />
        </div>
        <!-- Improvement Row Buttons -->
        <div class="improvement-row-buttons" v-if="!isSkeletonMode">
            <div class="improvement-row-buttons-inner">
                <slot name="buttons" />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { oButton, Popout, SelectArrowIcon, useShortKey } from '@opteo/components-next'
import { computed, PropType, ref } from 'vue'
import { Improvement } from '@opteo/types'

import ImprovementBatchIcon from './ImprovementBatchIcon.vue'
import ImprovementLocation from './ImprovementLocation.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import { isToolProvement, ToolProvement } from '@/composition/improvement/types'

export default {
    components: {
        ImprovementBatchIcon,
        ImprovementLocation,
        oButton,
        Skeleton,
        Popout,
        SelectArrowIcon,
    },
    props: {
        improvement: {
            required: true,
            type: Object as PropType<Improvement.Object | ToolProvement>,
        },
        isInteractive: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
    emits: ['improvement-opened'],
    setup(props, { emit }) {
        const improvementRow = ref()

        const openImprovement = () => {
            console.log('openImprovement in ImprovementRowContainer')
            if (!props.isInteractive) {
                return
            }

            emit('improvement-opened')
        }

        const isSkeletonMode = computed(
            () =>
                !isToolProvement(props.improvement) &&
                typeof props.improvement.improvement_id === 'undefined'
        )

        useShortKey({
            keys: ['enter'],
            onPressCallBack: () => openImprovement(),
            eventTarget: improvementRow,
        })

        return {
            openImprovement,
            isSkeletonMode,
            improvementRow,
            isToolProvement,
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';
.improvement-row-container {
    @include relative;
    @include mb-16;
    @include br-24;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    outline: none;
}

// TODO(alex): This is just for fun, pls remove
.improvement-row.toolprovement {
    background: -webkit-linear-gradient(90deg, #95d7e3, #eb76ff);
}

.improvement-row {
    @include flex;
    @include items-center;
    @include pl-24;
    @include br-24;
    @include opteo-background;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    outline: none;
    overflow: hidden;
}
.improvement-row:focus {
    box-shadow: $opteo-shadow-focus;
    outline: none;
}
.improvement-row:active {
    outline: none;
    box-shadow: $opteo-shadow-focus;
    transform: translate3d(0, 1px, 0);
    -webkit-transform: translate3d(0, 1px, 0);
    -moz-transform: translate3d(0, 1px, 0);
}

.improvement-row:active ~ .improvement-row-icon {
    transform: translate3d(0, 1px, 0);
    -webkit-transform: translate3d(0, 1px, 0);
    -moz-transform: translate3d(0, 1px, 0);
}
.improvement-row:active ~ .improvement-row-buttons {
    transform: translate3d(0, 1px, 0);
    -webkit-transform: translate3d(0, 1px, 0);
    -moz-transform: translate3d(0, 1px, 0);
}

.improvement-row.interactive {
    cursor: pointer;
}

.improvement-name {
    @include opteo-foreground;
    @include ml-44;
    @include mr-20;
    @include fw-600;
    white-space: nowrap;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.36px;
}
.improvement-row-icon {
    @include absolute;
    top: 0;
    left: 1.5rem;
    height: 100%;
    @include flex;
    @include items-center;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
}
.improvement-row-buttons {
    display: none;
    @include items-center;
    @include absolute;
    @include right-0;
    @include top-0;
    @include h-100;
    @include pl-4;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
}
.improvement-row-buttons-inner {
    @include flex;
    @include items-center;
    position: relative;
    z-index: 2;
    padding-right: 1.5rem;
}
.improvement-row-buttons::before {
    content: '';
    background: #fff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 80%);
    width: 6rem;
    left: -6rem;
    height: calc(100% - 8px);
    top: 4px;
    position: absolute;
    pointer-events: none;
}
.o-dark-mode .improvement-row-buttons::before {
    background: linear-gradient(90deg, rgba(16, 16, 21, 0) 0, rgb(16, 16, 21) 80%);
}
.o-dark-mode .improvement-row-buttons::after {
    background: rgb(16, 16, 22);
}

@media (min-width: $mq-1024-max) {
    .improvement-row-buttons {
        @include flex;
        @include bg-opteo-white;
        align-items: center;
    }
}
</style>
