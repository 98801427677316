<template>
    <PageContent v-if="!improvementOpen" class="improvement-rows" :key="domainId">
        <!-- Active Improvements -->
        <div v-if="!improvementsLoading && !loadingEmptyState">
            <div v-if="!mockEmptyState">
                <TransitionGroup name="list" :css="shouldEnableParentTransition">
                    <ImprovementGroup
                        v-for="(improvementGroup, index) in improvementsGrouped"
                        :key="improvementGroup.label"
                    >
                        <template v-slot:header>
                            <!-- Priority -->
                            <ColorTag
                                v-if="improvementSort === 'priority'"
                                :color="getPriorityColor(improvementGroup.label)"
                                :title="improvementGroup.label"
                                :content="improvementGroup.subLabel"
                            />
                            <!-- Type -->
                            <ColorTag
                                v-if="improvementSort === 'type'"
                                color="blue"
                                :title="improvementGroup.label"
                                content=""
                            />
                            <!-- Created Date -->
                            <ColorTag
                                v-if="improvementSort === 'created'"
                                color="blue"
                                :title="improvementGroup.label"
                                content=""
                            />
                            <!-- Batchable -->
                            <ColorTag
                                v-if="improvementSort === 'batch_only'"
                                color="blue"
                                :title="improvementGroup.label"
                                :content="improvementGroup.subLabel"
                            />
                            <oButton
                                @clicked="toggleImprovementGroup(improvementGroup)"
                                size="small"
                                color="white"
                                classes="select-all-button"
                            >
                                Select All
                            </oButton>
                        </template>

                        <template v-slot:content>
                            <TransitionGroup
                                name="list"
                                @before-leave="() => toggleParentTransition(true)"
                                @after-leave="() => toggleParentTransition(false)"
                            >
                                <template
                                    v-for="improvement in improvementGroup.improvementsInGroup"
                                    :key="improvement.improvement_id"
                                >
                                    <ImprovementRowActive
                                        :improvement="improvement"
                                        :queued-improvement="findQueuedImprovement(improvement)"
                                        :queue-running="queueRunning"
                                        :batch-queue-running="batchQueueRunning"
                                    />
                                </template>
                                <Spacer
                                    v-if="index != improvementsGrouped.length - 1"
                                    :key="'spacer-' + index"
                                />
                            </TransitionGroup>
                        </template>
                    </ImprovementGroup>
                    <!-- Logo Spacer -->
                    <div
                        v-if="improvements.length > 0 && !improvementsLoading && !loadingEmptyState"
                        :class="[batchBarOpen ? 'extra-margin' : '', 'footer-logo-container']"
                        key="logo-spacer"
                    >
                        <OpteoLogo :width="44" :height="44" class="footer-logo-spacer" />
                    </div>
                </TransitionGroup>
            </div>
            <div v-else>
                <!-- Empty State (All improvements completed) -->
                <div
                    v-if="emptyState?.status === EmptyStates.CompletedImps"
                    class="improvements-empty-state-container"
                >
                    <img src="@/assets/img/noActiveImprovements.png" style="height: 218px" />
                    <Spacer height="2rem" />
                    <div class="center" style="max-width: 366px">
                        <Text as="h5" size="f-5" weight="600" align="center">
                            Fresh Improvements Incoming
                        </Text>
                        <Spacer height="1rem" />
                        <Text as="p" size="f-8" align="center">
                            Opteo is gathering fresh data and analysing your account for potential
                            improvements. Check back in a few minutes.
                        </Text>
                    </div>
                </div>
                <!-- Empty State (First improvements) -->
                <div
                    v-if="emptyState?.status === EmptyStates.FirstImps"
                    class="improvements-empty-state-container"
                >
                    <img src="@/assets/img/noActiveImprovements.png" style="height: 218px" />
                    <Spacer height="2rem" />
                    <div class="center" style="max-width: 366px">
                        <Text as="h5" size="f-5" weight="600" align="center">
                            Improvements are incoming
                        </Text>
                        <Spacer height="1rem" />
                        <Text as="p" size="f-8" align="center">
                            Opteo is gathering performance data and analysing your account for
                            improvements. Check back in a few minutes.
                        </Text>
                    </div>
                </div>
                <!-- Empty State (Zero spend) -->
                <div
                    v-if="emptyState?.status === EmptyStates.ZeroSpend"
                    class="improvements-empty-state-container"
                >
                    <div class="warning-icon-container">
                        <WarnIcon style="transform: scale(1.8333)" />
                    </div>
                    <Spacer height="2.5rem" />
                    <div class="center" style="max-width: 434px">
                        <Text as="h5" size="f-5" weight="600" align="center">
                            No Campaign Spend Detected
                        </Text>
                        <Spacer height="1.25rem" />
                        <Text as="p" size="f-8" align="center">
                            For Opteo to generate improvements, your account needs to have at least
                            one active campaign with spend. If you are seeing this message in error
                            and your account has active spend, please message support.
                        </Text>
                        <Spacer height="1.5rem" />
                        <oButton @clicked="openMessenger()">Message Customer Support</oButton>
                    </div>
                </div>
                <!-- Empty State (Campaigns paused) -->
                <div
                    v-if="emptyState?.status === EmptyStates.CampaignsPaused"
                    class="improvements-empty-state-container"
                >
                    <div class="warning-icon-container">
                        <WarnIcon style="transform: scale(1.8333)" />
                    </div>
                    <Spacer height="2.5rem" />
                    <div class="center" style="max-width: 434px">
                        <Text as="h5" size="f-5" weight="600" align="center">
                            Campaigns Paused — Over Budget
                        </Text>
                        <Spacer height="1.25rem" />
                        <Text as="p" size="f-8" align="center">
                            All active campaigns in this account have been paused because your spend
                            this month has exceeded your monthly budget. Campaigns are scheduled to
                            resume spending on <b>{{ resumeSpending }}</b
                            >. To reactivate your campaigns now, or adjust your monthly budget,
                            click below.
                        </Text>
                        <Spacer height="1.5rem" />
                        <div class="multiple-btn-container">
                            <router-link :to="domainSettingsRoute">
                                <oButton color="white">Adjust Budget</oButton>
                            </router-link>
                            <Spacer width="0.625rem" />
                            <oButton
                                ref="reactivatePausedCampaignsButton"
                                :loading="reactivatingCampaignsLoader"
                                @clicked="reactivateCampaigns()"
                            >
                                Reactivate Campaigns
                            </oButton>
                        </div>
                        <Spacer height="1.5rem" />
                        <Text as="p" size="f-10" color="gray" align="center">
                            Clicking <b>Reactivate Campaigns</b> will reactivate your campaigns
                            immediately. Please adjust your budget settings to prevent these
                            campaigns from being automatically paused again. Spend is checked
                            against your budget <b>once per hour</b>.
                        </Text>
                    </div>
                </div>
                <!-- Empty State (Lost write access) -->
                <div
                    v-if="emptyState?.status === EmptyStates.LostWriteAccess"
                    class="improvements-empty-state-container"
                >
                    <div class="center" style="max-width: 450px">
                        <div class="warning-icon-container">
                            <WarnIcon style="transform: scale(1.8333)" />
                        </div>
                        <Spacer height="2.5rem" />
                        <Text as="h5" size="f-5" weight="600" align="center">
                            Account Write Access Denied
                        </Text>
                        <Spacer height="1.25rem" />
                        <Text as="p" size="f-8" align="center">
                            Opteo no longer has write access to your account, meaning changes can no
                            longer be pushed to Google Ads via Opteo. To continue using Opteo,
                            please fix your account access permissions in Google Ads.
                        </Text>
                        <Spacer height="1.5rem" />
                        <oButton @clicked="openMessenger()">Message Customer Support</oButton>
                    </div>
                </div>
            </div>
        </div>
        <!-- Skeleton -->
        <ImprovementGroup v-else>
            <template v-slot:header>
                <Skeleton :height="24" :width="189" />
                <oButton size="small" color="white" classes="select-all-button" disabled>
                    Select All
                </oButton>
            </template>
            <template v-slot:content>
                <div v-for="skid in 20" :key="skid">
                    <ImprovementRowActive
                        :improvement="EMPTY_IMPROVEMENT"
                        :queued-improvement="undefined"
                        :queue-running="false"
                        :batch-queue-running="false"
                    />
                </div>
            </template>
        </ImprovementGroup>
        <!-- Batch Bar -->
        <Transition name="fade-up">
            <ImprovementBatchBar
                v-if="batchBarOpen"
                v-model="batchBarOpen"
                :batched-improvements="batchedImprovements"
                :batched-improvements-length="batchedImprovementsLength"
                :batch-queue-running="batchQueueRunning"
            />
        </Transition>
    </PageContent>
    <router-view v-else />
</template>

<script lang="ts">
export default {
    name: 'ActiveImprovements',
}
</script>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { computed, ref, watch, onMounted, onUnmounted, onBeforeUnmount, nextTick } from 'vue'
import { ColorTag, oButton, Text, Spacer, OpteoLogo, WarnIcon } from '@opteo/components-next'

import { Routes } from '@/router/routes'

import { useActiveImprovements } from '@/composition/improvement/useActiveImprovements'
import { useImprovementQueue } from '@/composition/improvement/useImprovementQueue'
import { useBatchBox } from '@/composition/improvement/useBatchBox'
import { useImprovementEmptyStates } from '@/composition/improvement/useImprovementEmptyStates'
import { useDomain } from '@/composition/domain/useDomain'
import PageContent from '@/layouts/PageContent.vue'
import ImprovementRowActive from '@/components/improvement/ImprovementRowActive.vue'
import ImprovementBatchBar from '@/components/improvement/ImprovementBatchBar.vue'
import ImprovementGroup from '@/components/improvement/ImprovementGroup.vue'
import Skeleton from '@/components/util/Skeleton.vue'

import type { QueuedImprovement } from '@/composition/improvement/useImprovementQueue'
import {
    isToolProvement,
    type EnhancedImprovement,
    type ToolProvement,
} from '@/composition/improvement/types'

const {
    improvements,
    improvementsGrouped,
    loading: improvementsLoading,
    improvementSort,
} = useActiveImprovements()

const { queuedImprovements, queueRunning, cleanupQueue } = useImprovementQueue()

const { batchedImprovements, batchQueueRunning, toggleImprovementGroup, cleanupBatch } =
    useBatchBox()

const {
    emptyState,
    loadingEmptyState,
    formattedReEnableDate: resumeSpending,
    reactivatePausedCampaigns,
    reactivatingCampaignsLoader,
    EmptyStates,
    mockEmptyState,
} = useImprovementEmptyStates()

const { domainId } = useDomain()

const EMPTY_IMPROVEMENT = {} as EnhancedImprovement

const reactivatePausedCampaignsButton = ref()

async function reactivateCampaigns() {
    await reactivatePausedCampaigns()
    reactivatePausedCampaignsButton.value.flashSuccess()
}

const domainSettingsRoute = computed(() => {
    return {
        name: Routes.DomainSettings,
        params: {
            scroll_position: 'update-budget',
        },
    }
})

const { openMessenger } = useIntercom()

const route = useRoute()

// Bind parent TransitionGroup css to shouldEnableParentTransition so transition only happens when improvement row leaves
const shouldEnableParentTransition = ref(false)
const toggleParentTransition = async (toggleTo: boolean) => {
    await nextTick()
    shouldEnableParentTransition.value = toggleTo
}

const improvementOpen = computed(() => !!route.params.improvementId)

// automatically open or close batchbar
const batchBarOpen = ref(false)
const batchedImprovementsLength = computed(() => batchedImprovements.value.length)

watch(batchedImprovementsLength, (newVal, oldVal) => {
    if (newVal && !oldVal) {
        batchBarOpen.value = true
    }
    if (!newVal && oldVal) {
        batchBarOpen.value = false
    }
})

const findQueuedImprovement = (improvement: EnhancedImprovement | ToolProvement) => {
    if (isToolProvement(improvement)) {
        return undefined
    }
    const queuedImprovement: QueuedImprovement | undefined = queuedImprovements.value.find(
        i => i.id === improvement.improvement_id
    )

    return queuedImprovement
}

onMounted(() => {
    window.addEventListener('beforeunload', cleanupBatch)
    window.addEventListener('beforeunload', cleanupQueue)
})

onBeforeUnmount(() => {
    // Cleanup batch queue and standard queue also on refresh or back/forward click
    window.removeEventListener('beforeunload', cleanupBatch)
    window.removeEventListener('beforeunload', cleanupQueue)
})

onUnmounted(() => cleanupBatch())

const getPriorityColor = (label: string) => {
    if (label === 'High Priority') {
        return 'green'
    }
    if (label === 'Medium Priority') {
        return 'blue'
    }
    if (label === 'Low Priority') {
        return 'gray'
    }
    return 'blue'
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';

.improvement-rows {
    @include mb-48;
}
.select-all-button {
    @include opteo-background;
    @include opteo-foreground;
    @include ml-12;
}

.improvement-queue-row {
    @include mh-24;
    @include mt-12;
    @include mb-0;
    position: relative;
    display: flex;
    @include items-center;
    @include justify-between;
}

.improvements-empty-state-container {
    @include flex;
    @include items-center;
    @include justify-center;
    @include tc;
    flex-direction: column;
    height: 30rem;
}

.improvement-queue-row .imp-title {
    @include mb-12;
}

.improvement-queue-row .imp-status-icon {
    @include mb-12;
}

.footer-logo-container {
    @include w-100;
    @include flex-center;
    @include pt-28;
}
.footer-logo-spacer {
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.2;
}

// Transitions

// List
.list-move,
.list-enter-active,
.list-leave-active {
    transition: all 0.72s cubic-bezier(0.19, 1, 0.22, 1);
}

.list-enter-from {
    opacity: 0;
    transform: translateX(2rem);
}

.list-enter-to {
    transition-delay: 0.72s;
}
.list-move {
    transition-delay: 0.36s;
}

.list-leave-to {
    opacity: 0;
    transform: translateX(2rem);
}

// Ensure leaving items are taken out of layout flow so
// that moving animations can be calculated correctly.
.list-leave-active {
    position: absolute;
    width: 100%;
}

// Fade Up
.fade-up-enter-active {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.fade-up-leave-active {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.fade-up-enter-from,
.fade-up-leave-to {
    transform: translate3d(0, 0.75rem, 0);
    opacity: 0;
}

@media screen and (max-width: $mq-767-max) {
    .improvements-empty-state-container {
        margin-bottom: 6rem;
    }
    .footer-logo-container.extra-margin {
        margin-bottom: 11.375rem;
    }
}

.warning-icon-container {
    @include flex-center;
    @include container;
    @include bg-opteo-white;
    @include pa-16;
    @include br-999;
    width: 5rem;
    height: 5rem;
    margin: 0 auto;
}
.multiple-btn-container {
    @include flex-center;
    @include relative;
}
.multiple-btn-container .tooltip {
    @include absolute;
    right: 38px;
}

.lost-write-access-container {
    @include pa-48;
    @include w-100;
    @include flex-center;
}
</style>
